import { Ref, ref, watch } from "vue";

const sessionStorageRefsKeys: string[] = []
// It is important to primarily use sessionStorage, because it is scoped to the current tab. localStorage is a fallback
// for when sessionStorage is not supported.
const storage = isSessionStorageSupported() ? sessionStorage : localStorage;

function persistKey(key: string) {
    sessionStorageRefsKeys.push(key);
    storage.setItem('__shared_keys', JSON.stringify(sessionStorageRefsKeys))
}

export function clearSessionStorageRefs() {
    const keysString = storage.getItem('__shared_keys')

    if (!keysString) {
        return
    }

    const keys = JSON.parse(keysString) as string[]
    keys.forEach(key => storage.removeItem(key))
}

export function useSessionStorageRef<T>(options: { key: string, defaultValue: T, ignore?: string[] }) {
    const innerRef = ref(options.defaultValue) as Ref<T>

    persistKey(options.key)

    const cached = storage.getItem(options.key)

    if (cached && cached !== "undefined") {
        innerRef.value = JSON.parse(cached) as T
    }

    watch(
        innerRef,
        (val) => {
            try {
                const json = JSON.stringify(val, (key, value) => {
                    if (options.ignore?.some(x => x === key)) {
                        return null
                    }
                    return value;
                })
                storage.setItem(options.key, json);
            } catch {
                return options.defaultValue
            }
        }, { deep: true }
    );

    return innerRef;
}

export const renderSnippet = (element: any, snippet: string | null): void => {
    if (!snippet) {
        return
    }

    element.innerHTML = snippet
    const scriptsTags = element.getElementsByTagName('script')
    // This is necessary otherwise the scripts tags are not going to be evaluated
    for (let i = 0; i < scriptsTags.length; i++) {
        const parentNode = scriptsTags[i].parentNode
        const newScriptTag = document.createElement('script')
        newScriptTag.type = 'text/javascript'
        newScriptTag.text = scriptsTags[i].text
        parentNode.removeChild(scriptsTags[i])
        parentNode.appendChild(newScriptTag)
    }
}

// It is likely possible to check window.sessionStorage directly, but this seems like a more robust way to check if it is actually supported.
function isSessionStorageSupported() {
    try {
        const uniqueKey = '49910604-0ee1-449e-9369-6b452efffb21';
        const storage = window.sessionStorage;
        storage.setItem(uniqueKey, 'test');
        storage.removeItem(uniqueKey);
        return true;
    } catch (e) {
        return false;
    }
}