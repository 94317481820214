<template>
    <div>
        <div :class="{ 'hide': !isChallengeInteractive, 'mt-2': isChallengeInteractive }" id="turnstile-container"></div>
        <slot :isChallengeExecuted="isChallengeExecuted" :isChallengeInteractive="isChallengeInteractive"></slot>
    </div>
</template>

<script lang="ts">
import {defineComponent, onMounted, ref} from "vue";

export default defineComponent({
    name: "v-turnstile-challenge",
    props: {
        turnstileSiteKey: {
            type: String,
            required: true
        },
        responseFieldName: {
            type: String,
            required: false
        }
    },
    setup(props) {
        const isChallengeInteractive = ref(false)
        const isChallengeExecuted = ref(false)

        onMounted(() => {
            window.turnstile.render('#turnstile-container', {
                sitekey: props.turnstileSiteKey,
                'response-field-name': props.responseFieldName ?? 'Data.TurnstileResponse',
                appearance: 'interaction-only',
                callback: () => {
                    isChallengeExecuted.value = true
                },
                'error-callback': () => {
                    isChallengeExecuted.value = true
                },
                'before-interactive-callback': () => isChallengeInteractive.value = true
            });
        })

        return {isChallengeInteractive, isChallengeExecuted}
    }
})

</script>

<style scoped>
    .hide {
        width: 0;
        height: 0;
        overflow: hidden;
    }
</style>