import VRuleAutomationsOption from "./v-rule-automations-option.vue";
import VRuleAutomationsWrapper from "./v-rule-automations-wrapper.vue";
import VTestUser from "./v-test-user.vue";

export default {
    install: (Vue) => {
        Vue.component("v-rule-automations-options", VRuleAutomationsOption);
        Vue.component("v-rule-automations-wrapper", VRuleAutomationsWrapper);
        Vue.component("v-test-user", VTestUser);
    }
}