<template>
    <div ref="container">
        <slot :is-checked="isChecked"></slot>
    </div>
</template>
<script>
import {onMounted, ref} from "vue";

export default {
    props: {
        checked: {
            type: Boolean,
            default: null
        },
        small: {
            type: Boolean,
            default: false
        },
    },
    setup(props, {emit}) {
        const isChecked = ref(false);
        const container = ref(null);

        onMounted(() => {
            const label = container.value.getElementsByTagName('label')[0];

            label.classList.add('ratsit-checkbox-toggle');

            if (props.small) {
                label.classList.add('ratsit-checkbox-toggle--small');
            }

            const checkbox = label.firstElementChild;
            isChecked.value = checkbox.checked;

            toggleCheckbox(checkbox)

            checkbox.addEventListener('change',
                () => {
                    isChecked.value = checkbox.checked;
                    emit('change', checkbox.checked);
                    toggleCheckbox(checkbox);
                });

            checkbox.addEventListener('focus',
                () => {
                    setFocusOnText(checkbox);
                });

            checkbox.addEventListener('blur',
                () => {
                    looseFocusOnText(checkbox);
                });

            setCheckboxStatus(label);
        })

        return {
            isChecked,
            container
        }
    }
};

function toggleCheckbox(checkbox) {

    const label = checkbox.parentElement;


    if (!checkbox) return;

    if (checkbox.checked) {
        label.classList.add('is-checked');
        label.classList.remove('pulse-timer-turquoise');
    }
    else {
        label.classList.remove('is-checked');
    }


}

function setCheckboxStatus(label) {

    const checkbox = label.firstChild;

    if (checkbox.checked) {
        label.classList.add('is-checked');
    }
}

function setFocusOnText(checkbox) {
    checkbox.nextElementSibling.style.border = '1px dotted #beccd3';
}

function looseFocusOnText(checkbox) {
    checkbox.nextElementSibling.style.border = '1px solid transparent';
}
</script>