import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, vModelSelect as _vModelSelect, vModelText as _vModelText, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "col-12 col-xl-10 offset-xl-1" }
const _hoisted_2 = { class: "mt-3" }
const _hoisted_3 = {
  id: "error",
  class: "info-box validation-summary-errors"
}
const _hoisted_4 = { class: "block-info-text" }
const _hoisted_5 = {
  key: 0,
  id: "created-user"
}
const _hoisted_6 = { key: 0 }
const _hoisted_7 = ["href"]
const _hoisted_8 = { key: 1 }
const _hoisted_9 = ["onClick"]
const _hoisted_10 = { id: "advanced-container" }
const _hoisted_11 = { class: "block__section" }
const _hoisted_12 = { class: "mt-2" }
const _hoisted_13 = { class: "form-horizontal" }
const _hoisted_14 = { class: "row" }
const _hoisted_15 = { class: "col-sm-12 col-lg-6 form-group form-group-dash mt-1" }
const _hoisted_16 = { class: "input-select" }
const _hoisted_17 = { class: "col-sm-12 col-lg-6 form-group form-group-dash mt-1" }
const _hoisted_18 = { class: "input-field" }
const _hoisted_19 = { class: "row" }
const _hoisted_20 = { class: "col-sm-12 col-lg-6 form-group form-group-dash mt-2" }
const _hoisted_21 = { class: "input-field" }
const _hoisted_22 = { class: "col-sm-12 col-lg-6 form-group form-group-dash mt-2" }
const _hoisted_23 = { class: "input-field" }
const _hoisted_24 = { class: "row" }
const _hoisted_25 = { class: "col-sm-12 col-lg-6 form-group form-group-dash mt-2" }
const _hoisted_26 = { class: "input-field" }
const _hoisted_27 = { class: "col-sm-12 col-lg-6 form-group form-group-dash mt-2" }
const _hoisted_28 = { class: "input-field" }
const _hoisted_29 = { class: "row" }
const _hoisted_30 = { class: "col-sm-12 col-lg-6 form-group form-group-dash mt-2" }
const _hoisted_31 = { class: "input-field" }
const _hoisted_32 = { class: "col-sm-12 col-lg-6 form-group form-group-dash mt-2" }
const _hoisted_33 = { class: "input-field" }
const _hoisted_34 = { class: "row" }
const _hoisted_35 = { class: "col-sm-12 col-lg-6 form-group form-group-dash mt-2" }
const _hoisted_36 = { class: "input-field" }
const _hoisted_37 = { class: "col-sm-12 col-lg-6 form-group form-group-dash mt-2" }
const _hoisted_38 = { class: "input-field" }
const _hoisted_39 = { class: "row" }
const _hoisted_40 = { class: "col-sm-12 col-lg-6 form-group form-group-dash mt-2" }
const _hoisted_41 = { class: "input-field" }
const _hoisted_42 = { class: "col-sm-12 col-lg-6 form-group form-group-dash mt-2" }
const _hoisted_43 = { class: "input-field" }
const _hoisted_44 = { class: "block block--primary" }
const _hoisted_45 = { class: "block__section mt-2" }
const _hoisted_46 = { class: "row" }
const _hoisted_47 = { class: "col-md-12" }
const _hoisted_48 = { class: "form-horizontal" }
const _hoisted_49 = { class: "row" }
const _hoisted_50 = { class: "col-sm-12 col-lg-6 form-group form-group-dash mt-1" }
const _hoisted_51 = { class: "input-field" }
const _hoisted_52 = { class: "col-sm-12 col-lg-6 form-group form-group-dash mt-1" }
const _hoisted_53 = ["onClick"]
const _hoisted_54 = { key: 0 }
const _hoisted_55 = { class: "row" }
const _hoisted_56 = { class: "col-sm-12 col-lg-6 form-group form-group-dash mt-2" }
const _hoisted_57 = { class: "input-select" }
const _hoisted_58 = { class: "row" }
const _hoisted_59 = { class: "col-sm-12 col-lg-6 form-group form-group-dash mt-2" }
const _hoisted_60 = { class: "input-select" }
const _hoisted_61 = { class: "col-sm-12 col-lg-6 form-group form-group-dash mt-2" }
const _hoisted_62 = { class: "input-select" }
const _hoisted_63 = { class: "row" }
const _hoisted_64 = { class: "col-sm-12 col-lg-6 form-group form-group-dash mt-2" }
const _hoisted_65 = { class: "input-select" }
const _hoisted_66 = { class: "row mt-3" }
const _hoisted_67 = { class: "col-md-12 mb-3" }
const _hoisted_68 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_content_toggle = _resolveComponent("v-content-toggle")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _withDirectives(_createElementVNode("div", null, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("ul", null, [
            _createElementVNode("li", null, _toDisplayString(_ctx.message), 1 /* TEXT */)
          ])
        ])
      ], 512 /* NEED_PATCH */), [
        [_vShow, _ctx.error]
      ]),
      _cache[107] || (_cache[107] = _createTextVNode()),
      _withDirectives(_createElementVNode("div", _hoisted_4, [
        _createElementVNode("h4", null, _toDisplayString(_ctx.message), 1 /* TEXT */),
        _cache[39] || (_cache[39] = _createTextVNode()),
        (_ctx.createdUser)
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _createElementVNode("ul", null, [
                (_ctx.isGeneratorEmail)
                  ? (_openBlock(), _createElementBlock("li", _hoisted_6, [
                      _cache[21] || (_cache[21] = _createTextVNode("Email: ")),
                      _createElementVNode("a", { href: _ctx.generatorEmailLink }, _toDisplayString(_ctx.createdUser.email), 9 /* TEXT, PROPS */, _hoisted_7),
                      _cache[22] || (_cache[22] = _createTextVNode()),
                      _createElementVNode("button", {
                        class: "btn btn-tertiary",
                        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.copyToClipboard(_ctx.createdUser.email)))
                      }, "Kopiera")
                    ]))
                  : (_openBlock(), _createElementBlock("li", _hoisted_8, "Email: " + _toDisplayString(_ctx.createdUser.email), 1 /* TEXT */)),
                _cache[23] || (_cache[23] = _createTextVNode()),
                _createElementVNode("li", null, "Aktiverad: " + _toDisplayString(_ctx.createdUser.activated), 1 /* TEXT */),
                _cache[24] || (_cache[24] = _createTextVNode()),
                _createElementVNode("li", null, "Validerad: " + _toDisplayString(_ctx.createdUser.validated), 1 /* TEXT */),
                _cache[25] || (_cache[25] = _createTextVNode()),
                _createElementVNode("li", null, "Plus: " + _toDisplayString(_ctx.createdUser.plus), 1 /* TEXT */),
                _cache[26] || (_cache[26] = _createTextVNode()),
                _createElementVNode("li", null, "Kön: " + _toDisplayString(_ctx.createdUser.gender), 1 /* TEXT */),
                _cache[27] || (_cache[27] = _createTextVNode()),
                _createElementVNode("li", null, "Personnummer: " + _toDisplayString(_ctx.createdUser.pNr), 1 /* TEXT */),
                _cache[28] || (_cache[28] = _createTextVNode()),
                _createElementVNode("li", null, "Förnamn: " + _toDisplayString(_ctx.createdUser.fName), 1 /* TEXT */),
                _cache[29] || (_cache[29] = _createTextVNode()),
                _createElementVNode("li", null, "Efternamn: " + _toDisplayString(_ctx.createdUser.lName), 1 /* TEXT */),
                _cache[30] || (_cache[30] = _createTextVNode()),
                _createElementVNode("li", null, [
                  _createTextVNode("Lösenord: " + _toDisplayString(_ctx.createdUser.password) + " ", 1 /* TEXT */),
                  _createElementVNode("button", {
                    class: "btn btn-tertiary",
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.copyToClipboard(_ctx.createdUser.password)))
                  }, "Kopiera")
                ]),
                _cache[31] || (_cache[31] = _createTextVNode()),
                _createElementVNode("li", null, "Organisatonsnummer: " + _toDisplayString(_ctx.createdUser.orgNr), 1 /* TEXT */),
                _cache[32] || (_cache[32] = _createTextVNode()),
                _createElementVNode("li", null, "Företagsnamn: " + _toDisplayString(_ctx.createdUser.orgName), 1 /* TEXT */),
                _cache[33] || (_cache[33] = _createTextVNode()),
                _createElementVNode("li", null, "Adress: " + _toDisplayString(_ctx.createdUser.adr), 1 /* TEXT */),
                _cache[34] || (_cache[34] = _createTextVNode()),
                _createElementVNode("li", null, "Postnummer: " + _toDisplayString(_ctx.createdUser.postNr), 1 /* TEXT */),
                _cache[35] || (_cache[35] = _createTextVNode()),
                _createElementVNode("li", null, "Stad: " + _toDisplayString(_ctx.createdUser.city), 1 /* TEXT */),
                _cache[36] || (_cache[36] = _createTextVNode()),
                _createElementVNode("li", null, "Telefonnummer: " + _toDisplayString(_ctx.createdUser.teleNr), 1 /* TEXT */),
                _cache[37] || (_cache[37] = _createTextVNode()),
                _createElementVNode("li", null, "Saldo Ratsitkonto: " + _toDisplayString(_ctx.createdUser.ratsitPoints), 1 /* TEXT */)
              ]),
              _cache[38] || (_cache[38] = _createTextVNode()),
              _createElementVNode("button", {
                class: "btn btn-primary",
                onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.signInTestUser && _ctx.signInTestUser(...args)))
              }, "Logga in med denna användare")
            ]))
          : _createCommentVNode("v-if", true)
      ], 512 /* NEED_PATCH */), [
        [_vShow, _ctx.showCreatedUser]
      ]),
      _cache[108] || (_cache[108] = _createTextVNode()),
      _cache[109] || (_cache[109] = _createElementVNode("div", { class: "block__header" }, [
        _createElementVNode("h1", null, "Skapa användare för Test"),
        _createTextVNode(),
        _createElementVNode("p", null, "Skapa användare utan att själva behöva gå igenom alla steg för detta. Använd standard-inställningarna\n                    genom att direkt skapa en användare nedan eller klicka Avancerat för att själv ställa in olika\n                    detaljer."),
        _createTextVNode(),
        _createElementVNode("p", null, [
          _createTextVNode("Observera att om du väljer ett slumpat/fejkat personnummer finns det funktioner i Ratsit som "),
          _createElementVNode("strong", null, "inte"),
          _createTextVNode("\n                    kommer att fungera (ex Löneranking och Självbevakning).")
        ])
      ], -1 /* HOISTED */)),
      _cache[110] || (_cache[110] = _createTextVNode()),
      _createVNode(_component_v_content_toggle, { class: "mt-3" }, {
        default: _withCtx((slotProps) => [
          _createElementVNode("p", {
            class: "read-more mt-2 user-select-auto",
            onClick: ($event: any) => (slotProps.toggleShowHide($event))
          }, [
            _cache[40] || (_cache[40] = _createElementVNode("strong", null, [
              _createElementVNode("i", {
                class: "icon-settings-cog-outline",
                style: {"position":"relative","top":"2px","margin-right":"5px"}
              }),
              _createTextVNode("Avancerat")
            ], -1 /* HOISTED */)),
            _cache[41] || (_cache[41] = _createTextVNode()),
            _createElementVNode("span", {
              class: _normalizeClass(["read-more__toggle ms-2", { 'is-active' : slotProps.isVisible }])
            }, null, 2 /* CLASS */)
          ], 8 /* PROPS */, _hoisted_9),
          _cache[106] || (_cache[106] = _createTextVNode()),
          _createElementVNode("div", {
            style: {"display":"none"},
            class: _normalizeClass({ 'd-block' : slotProps.isVisible })
          }, [
            _createElementVNode("div", _hoisted_10, [
              _createElementVNode("div", _hoisted_11, [
                _cache[102] || (_cache[102] = _createElementVNode("h2", { class: "mt-3 mt-lg-4" }, "Avancerade inställningar - standard används om inget sätts här", -1 /* HOISTED */)),
                _cache[103] || (_cache[103] = _createTextVNode()),
                _cache[104] || (_cache[104] = _createElementVNode("p", null, "\n                                De här inställningarna behöver du bara ändra om du själv vill sätta det till något\n                                specifikt. Annars kommer standard-inställningar att väljas. Vad som valts får du reda på\n                                efter du skapat användaren.\n                            ", -1 /* HOISTED */)),
                _cache[105] || (_cache[105] = _createTextVNode()),
                _createElementVNode("div", _hoisted_12, [
                  _createElementVNode("div", _hoisted_13, [
                    _createElementVNode("div", _hoisted_14, [
                      _createElementVNode("div", _hoisted_15, [
                        _cache[43] || (_cache[43] = _createElementVNode("label", { class: "control-label" }, "Kön", -1 /* HOISTED */)),
                        _cache[44] || (_cache[44] = _createTextVNode(" ")),
                        _cache[45] || (_cache[45] = _createElementVNode("span", { class: "tooltip" }, [
                          _createElementVNode("i", { class: "icon-info" }),
                          _createElementVNode("span", {
                            class: "tooltip-text tooltip-text--small",
                            style: {"z-index":"100"}
                          }, "Välj kön, används ej om Personnumer väljs dock")
                        ], -1 /* HOISTED */)),
                        _cache[46] || (_cache[46] = _createTextVNode()),
                        _createElementVNode("div", _hoisted_16, [
                          _withDirectives(_createElementVNode("select", {
                            class: "form-control form-control-dashboard",
                            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.testUser.gender) = $event))
                          }, _cache[42] || (_cache[42] = [
                            _createElementVNode("option", { value: "slump" }, "Slump", -1 /* HOISTED */),
                            _createTextVNode(),
                            _createElementVNode("option", { value: "man" }, "Man", -1 /* HOISTED */),
                            _createTextVNode(),
                            _createElementVNode("option", { value: "kvinna" }, "Kvinna", -1 /* HOISTED */)
                          ]), 512 /* NEED_PATCH */), [
                            [_vModelSelect, _ctx.testUser.gender]
                          ])
                        ])
                      ]),
                      _cache[51] || (_cache[51] = _createTextVNode()),
                      _createElementVNode("div", _hoisted_17, [
                        _cache[47] || (_cache[47] = _createElementVNode("label", { class: "control-label" }, "Personnummer", -1 /* HOISTED */)),
                        _cache[48] || (_cache[48] = _createTextVNode(" ")),
                        _cache[49] || (_cache[49] = _createElementVNode("span", { class: "tooltip" }, [
                          _createElementVNode("i", { class: "icon-info" }),
                          _createElementVNode("span", { class: "tooltip-text" }, "Skriv in ett personnummer om du vill ha ett specifikt, 12 tecken, slumpas annars. Innebär att Löneranking och Självbevakning kanske inte fungerar om PNr inte finns på riktigt.")
                        ], -1 /* HOISTED */)),
                        _cache[50] || (_cache[50] = _createTextVNode()),
                        _createElementVNode("div", _hoisted_18, [
                          _withDirectives(_createElementVNode("input", {
                            class: "form-control form-control-dashboard",
                            type: "text",
                            placeholder: "Personnummer slumpas (YYYYMMDD-XXXX)",
                            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.testUser.pNr) = $event))
                          }, null, 512 /* NEED_PATCH */), [
                            [_vModelText, _ctx.testUser.pNr]
                          ])
                        ])
                      ])
                    ]),
                    _cache[97] || (_cache[97] = _createTextVNode()),
                    _createElementVNode("div", _hoisted_19, [
                      _createElementVNode("div", _hoisted_20, [
                        _cache[52] || (_cache[52] = _createElementVNode("label", { class: "control-label" }, "Förnamn", -1 /* HOISTED */)),
                        _cache[53] || (_cache[53] = _createTextVNode(" ")),
                        _cache[54] || (_cache[54] = _createElementVNode("span", { class: "tooltip" }, [
                          _createElementVNode("i", { class: "icon-info" }),
                          _createElementVNode("span", { class: "tooltip-text" }, "Om fältet lämnas tomt slumpas namnet")
                        ], -1 /* HOISTED */)),
                        _cache[55] || (_cache[55] = _createTextVNode()),
                        _createElementVNode("div", _hoisted_21, [
                          _withDirectives(_createElementVNode("input", {
                            class: "form-control form-control-dashboard",
                            type: "text",
                            placeholder: "Förnamn slumpas",
                            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.testUser.fName) = $event))
                          }, null, 512 /* NEED_PATCH */), [
                            [_vModelText, _ctx.testUser.fName]
                          ])
                        ])
                      ]),
                      _cache[60] || (_cache[60] = _createTextVNode()),
                      _createElementVNode("div", _hoisted_22, [
                        _cache[56] || (_cache[56] = _createElementVNode("label", { class: "control-label" }, "Efternamn", -1 /* HOISTED */)),
                        _cache[57] || (_cache[57] = _createTextVNode(" ")),
                        _cache[58] || (_cache[58] = _createElementVNode("span", { class: "tooltip" }, [
                          _createElementVNode("i", { class: "icon-info" }),
                          _createElementVNode("span", { class: "tooltip-text" }, "Om fältet lämnas tomt slumpas namnet")
                        ], -1 /* HOISTED */)),
                        _cache[59] || (_cache[59] = _createTextVNode()),
                        _createElementVNode("div", _hoisted_23, [
                          _withDirectives(_createElementVNode("input", {
                            class: "form-control form-control-dashboard",
                            type: "text",
                            placeholder: "Efternamn slumpas",
                            "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.testUser.lName) = $event))
                          }, null, 512 /* NEED_PATCH */), [
                            [_vModelText, _ctx.testUser.lName]
                          ])
                        ])
                      ])
                    ]),
                    _cache[98] || (_cache[98] = _createTextVNode()),
                    _createElementVNode("div", _hoisted_24, [
                      _createElementVNode("div", _hoisted_25, [
                        _cache[61] || (_cache[61] = _createElementVNode("label", { class: "control-label" }, "OrgNamn", -1 /* HOISTED */)),
                        _cache[62] || (_cache[62] = _createTextVNode(" ")),
                        _cache[63] || (_cache[63] = _createElementVNode("span", { class: "tooltip" }, [
                          _createElementVNode("i", { class: "icon-info" }),
                          _createElementVNode("span", { class: "tooltip-text" }, "Om fältet lämnas tomt sätts det till Invativas")
                        ], -1 /* HOISTED */)),
                        _cache[64] || (_cache[64] = _createTextVNode()),
                        _createElementVNode("div", _hoisted_26, [
                          _withDirectives(_createElementVNode("input", {
                            class: "form-control form-control-dashboard",
                            type: "text",
                            placeholder: "Orgnamn sätts till Invativa",
                            "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.testUser.orgName) = $event))
                          }, null, 512 /* NEED_PATCH */), [
                            [_vModelText, _ctx.testUser.orgName]
                          ])
                        ])
                      ]),
                      _cache[69] || (_cache[69] = _createTextVNode()),
                      _createElementVNode("div", _hoisted_27, [
                        _cache[65] || (_cache[65] = _createElementVNode("label", { class: "control-label" }, "OrgNr", -1 /* HOISTED */)),
                        _cache[66] || (_cache[66] = _createTextVNode(" ")),
                        _cache[67] || (_cache[67] = _createElementVNode("span", { class: "tooltip" }, [
                          _createElementVNode("i", { class: "icon-info" }),
                          _createElementVNode("span", { class: "tooltip-text" }, "Om fältet lämnas tomt sätts det till Invativas organisationsnummer. Används endats om Kontotyp är Företag.")
                        ], -1 /* HOISTED */)),
                        _cache[68] || (_cache[68] = _createTextVNode()),
                        _createElementVNode("div", _hoisted_28, [
                          _withDirectives(_createElementVNode("input", {
                            class: "form-control form-control-dashboard",
                            type: "text",
                            placeholder: "OrgNr sätts till Invativas",
                            "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.testUser.orgNr) = $event))
                          }, null, 512 /* NEED_PATCH */), [
                            [_vModelText, _ctx.testUser.orgNr]
                          ])
                        ])
                      ])
                    ]),
                    _cache[99] || (_cache[99] = _createTextVNode()),
                    _createElementVNode("div", _hoisted_29, [
                      _createElementVNode("div", _hoisted_30, [
                        _cache[70] || (_cache[70] = _createElementVNode("label", { class: "control-label" }, "Address", -1 /* HOISTED */)),
                        _cache[71] || (_cache[71] = _createTextVNode(" ")),
                        _cache[72] || (_cache[72] = _createElementVNode("span", { class: "tooltip" }, [
                          _createElementVNode("i", { class: "icon-info" }),
                          _createElementVNode("span", { class: "tooltip-text" }, "Om fältet lämnas tomt sätts det till Invativas")
                        ], -1 /* HOISTED */)),
                        _cache[73] || (_cache[73] = _createTextVNode()),
                        _createElementVNode("div", _hoisted_31, [
                          _withDirectives(_createElementVNode("input", {
                            class: "form-control form-control-dashboard",
                            type: "text",
                            placeholder: "Address sätts till Invativas",
                            "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.testUser.adr) = $event))
                          }, null, 512 /* NEED_PATCH */), [
                            [_vModelText, _ctx.testUser.adr]
                          ])
                        ])
                      ]),
                      _cache[78] || (_cache[78] = _createTextVNode()),
                      _createElementVNode("div", _hoisted_32, [
                        _cache[74] || (_cache[74] = _createElementVNode("label", { class: "control-label" }, "PostNr", -1 /* HOISTED */)),
                        _cache[75] || (_cache[75] = _createTextVNode(" ")),
                        _cache[76] || (_cache[76] = _createElementVNode("span", { class: "tooltip" }, [
                          _createElementVNode("i", { class: "icon-info" }),
                          _createElementVNode("span", { class: "tooltip-text" }, "Om fältet lämnas tomt sätts det till Invativas")
                        ], -1 /* HOISTED */)),
                        _cache[77] || (_cache[77] = _createTextVNode()),
                        _createElementVNode("div", _hoisted_33, [
                          _withDirectives(_createElementVNode("input", {
                            class: "form-control form-control-dashboard",
                            type: "text",
                            placeholder: "PostNr sätts till Invativas",
                            "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.testUser.postNr) = $event))
                          }, null, 512 /* NEED_PATCH */), [
                            [_vModelText, _ctx.testUser.postNr]
                          ])
                        ])
                      ])
                    ]),
                    _cache[100] || (_cache[100] = _createTextVNode()),
                    _createElementVNode("div", _hoisted_34, [
                      _createElementVNode("div", _hoisted_35, [
                        _cache[79] || (_cache[79] = _createElementVNode("label", { class: "control-label" }, "Stad", -1 /* HOISTED */)),
                        _cache[80] || (_cache[80] = _createTextVNode(" ")),
                        _cache[81] || (_cache[81] = _createElementVNode("span", { class: "tooltip" }, [
                          _createElementVNode("i", { class: "icon-info" }),
                          _createElementVNode("span", { class: "tooltip-text" }, "Om fältet lämnas tomt sätts det till Invativas")
                        ], -1 /* HOISTED */)),
                        _cache[82] || (_cache[82] = _createTextVNode()),
                        _createElementVNode("div", _hoisted_36, [
                          _withDirectives(_createElementVNode("input", {
                            class: "form-control form-control-dashboard",
                            type: "text",
                            placeholder: "Stad sätts till Invativas",
                            "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.testUser.city) = $event))
                          }, null, 512 /* NEED_PATCH */), [
                            [_vModelText, _ctx.testUser.city]
                          ])
                        ])
                      ]),
                      _cache[87] || (_cache[87] = _createTextVNode()),
                      _createElementVNode("div", _hoisted_37, [
                        _cache[83] || (_cache[83] = _createElementVNode("label", { class: "control-label" }, "Telenr", -1 /* HOISTED */)),
                        _cache[84] || (_cache[84] = _createTextVNode(" ")),
                        _cache[85] || (_cache[85] = _createElementVNode("span", { class: "tooltip" }, [
                          _createElementVNode("i", { class: "icon-info" }),
                          _createElementVNode("span", { class: "tooltip-text" }, "Om fältet lämnas tomt sätts det till Invativas")
                        ], -1 /* HOISTED */)),
                        _cache[86] || (_cache[86] = _createTextVNode()),
                        _createElementVNode("div", _hoisted_38, [
                          _withDirectives(_createElementVNode("input", {
                            class: "form-control form-control-dashboard",
                            type: "text",
                            placeholder: "Telenr sätts till Invativas",
                            "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.testUser.teleNr) = $event))
                          }, null, 512 /* NEED_PATCH */), [
                            [_vModelText, _ctx.testUser.teleNr]
                          ])
                        ])
                      ])
                    ]),
                    _cache[101] || (_cache[101] = _createTextVNode()),
                    _createElementVNode("div", _hoisted_39, [
                      _createElementVNode("div", _hoisted_40, [
                        _cache[88] || (_cache[88] = _createElementVNode("label", { class: "control-label" }, "Lösenord", -1 /* HOISTED */)),
                        _cache[89] || (_cache[89] = _createTextVNode(" ")),
                        _cache[90] || (_cache[90] = _createElementVNode("span", { class: "tooltip" }, [
                          _createElementVNode("i", { class: "icon-info" }),
                          _createElementVNode("span", { class: "tooltip-text" }, "Om fältet lämnas tomt sätts det till ratsitT3st")
                        ], -1 /* HOISTED */)),
                        _cache[91] || (_cache[91] = _createTextVNode()),
                        _createElementVNode("div", _hoisted_41, [
                          _withDirectives(_createElementVNode("input", {
                            class: "form-control form-control-dashboard",
                            type: "text",
                            placeholder: "Lösenord sätts till ratsitT3st",
                            "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.testUser.password) = $event))
                          }, null, 512 /* NEED_PATCH */), [
                            [_vModelText, _ctx.testUser.password]
                          ])
                        ])
                      ]),
                      _cache[96] || (_cache[96] = _createTextVNode()),
                      _createElementVNode("div", _hoisted_42, [
                        _cache[92] || (_cache[92] = _createElementVNode("label", { class: "control-label" }, "Ratsitkonto", -1 /* HOISTED */)),
                        _cache[93] || (_cache[93] = _createTextVNode(" ")),
                        _cache[94] || (_cache[94] = _createElementVNode("span", { class: "tooltip" }, [
                          _createElementVNode("i", { class: "icon-info" }),
                          _createElementVNode("span", { class: "tooltip-text" }, "Om fältet lämnas tomt sätts det till 200. Endast om kontotyp Företag.")
                        ], -1 /* HOISTED */)),
                        _cache[95] || (_cache[95] = _createTextVNode()),
                        _createElementVNode("div", _hoisted_43, [
                          _withDirectives(_createElementVNode("input", {
                            class: "form-control form-control-dashboard",
                            type: "text",
                            placeholder: "Poäng sätts till 200 om Företags-konto",
                            "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.testUser.ratsitPoints) = $event))
                          }, null, 512 /* NEED_PATCH */), [
                            [_vModelText, _ctx.testUser.ratsitPoints]
                          ])
                        ])
                      ])
                    ])
                  ])
                ])
              ])
            ])
          ], 2 /* CLASS */)
        ]),
        _: 1 /* STABLE */
      })
    ]),
    _cache[139] || (_cache[139] = _createTextVNode()),
    _createElementVNode("div", _hoisted_44, [
      _cache[137] || (_cache[137] = _createElementVNode("div", { class: "block__header" }, [
        _createElementVNode("h2", { class: "mt-3 mt-lg-5" }, "Grundinställningar")
      ], -1 /* HOISTED */)),
      _cache[138] || (_cache[138] = _createTextVNode()),
      _createElementVNode("div", _hoisted_45, [
        _createElementVNode("div", _hoisted_46, [
          _createElementVNode("div", _hoisted_47, [
            _createElementVNode("div", _hoisted_48, [
              _createElementVNode("div", _hoisted_49, [
                _createElementVNode("div", _hoisted_50, [
                  _cache[111] || (_cache[111] = _createElementVNode("label", { class: "control-label" }, "E-post", -1 /* HOISTED */)),
                  _cache[112] || (_cache[112] = _createTextVNode()),
                  _createElementVNode("div", _hoisted_51, [
                    _withDirectives(_createElementVNode("input", {
                      class: "form-control form-control-dashboard",
                      type: "text",
                      placeholder: "E-post för användaren, lämna tomt för Automatgenererad",
                      "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.testUser.email) = $event))
                    }, null, 512 /* NEED_PATCH */), [
                      [_vModelText, _ctx.testUser.email]
                    ])
                  ])
                ]),
                _cache[117] || (_cache[117] = _createTextVNode()),
                _createElementVNode("div", _hoisted_52, [
                  _createVNode(_component_v_content_toggle, null, {
                    default: _withCtx((slotProps) => [
                      _createElementVNode("div", {
                        class: "read-more user-select-auto",
                        onClick: ($event: any) => (slotProps.toggleShowHide($event))
                      }, [
                        _cache[113] || (_cache[113] = _createElementVNode("label", { class: "control-label" }, "Hur vill du få e-posten? ", -1 /* HOISTED */)),
                        _cache[114] || (_cache[114] = _createTextVNode()),
                        _createElementVNode("span", {
                          class: _normalizeClass(["read-more__toggle ms-2", { 'is-active' : slotProps.isVisible }])
                        }, null, 2 /* CLASS */)
                      ], 8 /* PROPS */, _hoisted_53),
                      _cache[116] || (_cache[116] = _createTextVNode()),
                      (slotProps.isVisible)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_54, _cache[115] || (_cache[115] = [
                            _createElementVNode("div", {
                              id: "emailinfo-container",
                              class: "mt-1"
                            }, [
                              _createElementVNode("ul", null, [
                                _createElementVNode("li", null, [
                                  _createElementVNode("strong", null, "Lämna tomt:"),
                                  _createTextVNode(" du får en slumpmässig e-post och en\n                                                        länk till en extern brevlåda. "),
                                  _createElementVNode("i", {
                                    class: "fa fa-info-circle",
                                    "data-toggle": "tooltip",
                                    "data-placement": "top",
                                    title: "Standard. Tjänsten https://generator.email/ används för detta."
                                  })
                                ]),
                                _createTextVNode(),
                                _createElementVNode("li", null, [
                                  _createElementVNode("strong", null, "GMail:"),
                                  _createTextVNode(" skapa och använda en GMail-adr med\n                                                        +(plus). Ex 'minepost+testare1@gmail.com'. "),
                                  _createElementVNode("i", {
                                    class: "fa fa-info-circle",
                                    "data-toggle": "tooltip",
                                    "data-placement": "top",
                                    title: "För mer kontroll och mindre strul."
                                  })
                                ]),
                                _createTextVNode(),
                                _createElementVNode("li", null, [
                                  _createElementVNode("a", {
                                    href: "https://www.mailinator.com/v3/#/#inboxpane",
                                    target: "_blank"
                                  }, "Mailinator"),
                                  _createTextVNode(": skapa enkelt en egen e-post\n                                                        via externa tjänsten. "),
                                  _createElementVNode("i", {
                                    class: "fa fa-info-circle",
                                    "data-toggle": "tooltip",
                                    "data-placement": "top",
                                    title: "En mellanvariant, enklare än GMail men kan strula då det är en extern tjänst."
                                  })
                                ])
                              ])
                            ], -1 /* HOISTED */)
                          ])))
                        : _createCommentVNode("v-if", true)
                    ]),
                    _: 1 /* STABLE */
                  })
                ])
              ]),
              _cache[133] || (_cache[133] = _createTextVNode()),
              _createElementVNode("div", _hoisted_55, [
                _createElementVNode("div", _hoisted_56, [
                  _cache[119] || (_cache[119] = _createElementVNode("label", { class: "control-label" }, "Kontotyp", -1 /* HOISTED */)),
                  _cache[120] || (_cache[120] = _createTextVNode()),
                  _createElementVNode("div", _hoisted_57, [
                    _withDirectives(_createElementVNode("select", {
                      class: "form-control form-control-dashboard",
                      "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.testUser.accountType) = $event))
                    }, _cache[118] || (_cache[118] = [
                      _createElementVNode("option", { value: "privat" }, "Privat", -1 /* HOISTED */),
                      _createTextVNode(),
                      _createElementVNode("option", { value: "ftg" }, "Företag", -1 /* HOISTED */)
                    ]), 512 /* NEED_PATCH */), [
                      [_vModelSelect, _ctx.testUser.accountType]
                    ])
                  ])
                ]),
                _cache[121] || (_cache[121] = _createTextVNode()),
                _cache[122] || (_cache[122] = _createElementVNode("div", { class: "col-sm-12 col-lg-6 form-group form-group-dash mt-2" }, null, -1 /* HOISTED */))
              ]),
              _cache[134] || (_cache[134] = _createTextVNode()),
              _createElementVNode("div", _hoisted_58, [
                _createElementVNode("div", _hoisted_59, [
                  _cache[124] || (_cache[124] = _createElementVNode("label", { class: "control-label" }, "Aktiverad", -1 /* HOISTED */)),
                  _cache[125] || (_cache[125] = _createTextVNode()),
                  _createElementVNode("div", _hoisted_60, [
                    _withDirectives(_createElementVNode("select", {
                      class: "form-control form-control-dashboard",
                      "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.testUser.activated) = $event))
                    }, _cache[123] || (_cache[123] = [
                      _createElementVNode("option", { value: "y" }, "Ja", -1 /* HOISTED */),
                      _createTextVNode(),
                      _createElementVNode("option", { value: "n" }, "Nej", -1 /* HOISTED */)
                    ]), 512 /* NEED_PATCH */), [
                      [_vModelSelect, _ctx.testUser.activated]
                    ])
                  ])
                ]),
                _cache[129] || (_cache[129] = _createTextVNode()),
                _createElementVNode("div", _hoisted_61, [
                  _cache[127] || (_cache[127] = _createElementVNode("label", { class: "control-label" }, "Abonnemang", -1 /* HOISTED */)),
                  _cache[128] || (_cache[128] = _createTextVNode()),
                  _createElementVNode("div", _hoisted_62, [
                    _withDirectives(_createElementVNode("select", {
                      class: "form-control form-control-dashboard",
                      "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.testUser.plus) = $event))
                    }, _cache[126] || (_cache[126] = [
                      _createElementVNode("option", { value: "n" }, "Nej", -1 /* HOISTED */),
                      _createTextVNode(),
                      _createElementVNode("option", { value: "plus1month" }, "Plus 1 mån", -1 /* HOISTED */),
                      _createTextVNode(),
                      _createElementVNode("option", { value: "premium1month" }, "Premium 1 mån", -1 /* HOISTED */),
                      _createTextVNode(),
                      _createElementVNode("option", { value: "premium1year" }, "Premium 1 år", -1 /* HOISTED */)
                    ]), 512 /* NEED_PATCH */), [
                      [_vModelSelect, _ctx.testUser.plus]
                    ])
                  ])
                ])
              ]),
              _cache[135] || (_cache[135] = _createTextVNode()),
              _createElementVNode("div", _hoisted_63, [
                _createElementVNode("div", _hoisted_64, [
                  _cache[131] || (_cache[131] = _createElementVNode("label", { class: "control-label" }, "Validerad", -1 /* HOISTED */)),
                  _cache[132] || (_cache[132] = _createTextVNode()),
                  _createElementVNode("div", _hoisted_65, [
                    _withDirectives(_createElementVNode("select", {
                      class: "form-control form-control-dashboard",
                      "onUpdate:modelValue": _cache[19] || (_cache[19] = ($event: any) => ((_ctx.testUser.validated) = $event))
                    }, _cache[130] || (_cache[130] = [
                      _createElementVNode("option", { value: "y" }, "Ja", -1 /* HOISTED */),
                      _createTextVNode(),
                      _createElementVNode("option", { value: "n" }, "Nej", -1 /* HOISTED */)
                    ]), 512 /* NEED_PATCH */), [
                      [_vModelSelect, _ctx.testUser.validated]
                    ])
                  ])
                ])
              ])
            ])
          ])
        ]),
        _cache[136] || (_cache[136] = _createTextVNode()),
        _createElementVNode("div", _hoisted_66, [
          _createElementVNode("div", _hoisted_67, [
            _createElementVNode("button", {
              disabled: _ctx.disableCreateTestUser,
              class: "btn btn-primary",
              onClick: _cache[20] || (_cache[20] = 
//@ts-ignore
(...args) => (_ctx.createTestUser && _ctx.createTestUser(...args)))
            }, "Skapa användaren\n                        ", 8 /* PROPS */, _hoisted_68)
          ])
        ])
      ])
    ])
  ]))
}