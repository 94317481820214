<template>
    <div v-if="validationError">
        <p class="text-center text-md-start mt-2">
            <component v-if="userMessageComponent" v-bind:is="userMessageComponent"></component>
            <span v-else>{{ userMessage }}</span>
        </p>
        <div class="text-center text-md-start mt-2">
            <div class="position-relative" style="z-index: 1">
                <div>
                    <a class="btn btn--large btn--full-width-mobile btn-tertiary btn--min-width-250 mt-1 mt-md-2"
                        @click="() => launchBankIdAppAndAuth(true)">Försök igen</a>
                </div>
                <div>
                    <a @click="cancelAndRedirect"
                        class="btn btn--large btn--full-width-mobile btn--min-width-250 btn-secondary mt-2">Avbryt</a>
                </div>
            </div>
            <img class="d-block d-sm-none bottom-illustration--fixed"
                src="/dist/assets/images/illustration-confused-small.svg" width="375" height="296"
                alt="Illustration - Något gick fel" />
            <img class="d-none d-sm-inline-block mt-3" src="/dist/assets/images/illustration-confused.svg" width="512"
                height="340" alt="Illustration - Något gick fel" />
        </div>
    </div>
    <div v-else>
        <div v-if="!validationComplete">
            <div class="text-center text-lg-start"
                v-if="!validationUserSignPending && !validationResume && !validationCanceled">
                <div v-if="isAndroid()">
                    <p class="mb-1">Starta BankID-appen.</p>
                </div>
                <div v-else>
                    <div class="loader-spinner mt-3">
                        <span>Laddar ...</span>
                    </div>
                    <p class="mb-1">Försöker starta BankID-appen.</p>
                </div>
                <a ref="launchLink" :href="getBankIdLaunchLink()">
                    Öppna BankID på den här enheten
                </a>
            </div>
            <div v-else class="text-center text-md-start">
                <div class="loader-spinner mt-3">
                    <span>Laddar ...</span>
                </div>
                <p class="text-start mt-1">
                    <component v-if="userMessageComponent" v-bind:is="userMessageComponent"></component>
                    <span v-else>{{ userMessage }}</span>
                </p>
            </div>
            <div class="text-center text-md-start">
                <a class="btn btn--large btn--full-width-mobile btn-tertiary mt-3" @click="cancelAndRedirect">Avbryt</a>
            </div>
        </div>
    </div>
</template>

<script>
import {onMounted, ref, watch} from "vue";
import { getBankIdLogic } from './bank-id-logic';
import VSegmenteringForm from "./v-segmentering-form.vue";
import { useSharedStore } from "@/Pages/Shared/Utility/shared-store";
import {useSessionStorageRef} from "@/Pages/Shared/Utility/sessionStorage";

export default {
    components: { VSegmenteringForm },
    setup(_, context) {
        const store = useSharedStore();
        const launchLink = ref(null);
        const {
            autoStartToken,
            userMessage,
            userMessageComponent,
            validationComplete,
            validationError,
            validationUserSignPending,
            validationResume,
            validationCanceled,
            authAndCollect,
            authAndCollectWithResume,
            cancel
        } = getBankIdLogic('/api/bank-id');



        watch(validationComplete, (newValue) => {
            if (newValue) {
                if(store.purpose === 'Anmarkning' || store.purpose === 'Kredit'){
                    context.emit('bivc', true)
                    store.clearMode()
                }
                else {
                    store.clearMode()
                    if (store.confirmRedirect === 'signIn') {
                        context.emit('go-to-page', 3)
                    } else {
                        location.href = store.confirmRedirect;
                    }
                }
            }
        });

        onMounted(async () => {
            await launchBankIdAppAndAuth();
        })

        const cancelAndRedirect = async () => {
            await cancel();
            store.clearMode();
            location.href = store.cancelRedirect
        }

        const launchBankIdAppAndAuth = async (forceNewOrder = false) => {
            if (isIos() && isSafari()) {
                await authAndCollectWithResume(forceNewOrder)
            } else {
                await authAndCollect();
            }

            if (!validationResume.value && !isAndroid()) {
                launchLink.value.click();
            }
        }
        const isChrome = () => /chrome|chromium|crios/i.test(navigator.userAgent);
        const isSafari = () => /safari/i.test(navigator.userAgent) && !isChrome() && !isFirefox();
        const isFirefox = () => /firefox|fxios/i.test(navigator.userAgent);
        const isIos = () => /ipad|iphone|ipod/i.test(navigator.userAgent);
        const isAndroid = () => /android/i.test(navigator.userAgent);

        const getBankIdLaunchLink = () => {
            if (isIos() && isSafari()) {
                return `https://app.bankid.com/?autostarttoken=${autoStartToken.value}&redirect=${getRedirectLink()}`;
            }

            return `bankid:///?autostarttoken=${autoStartToken.value}&redirect=${getRedirectLink()}`;
        }

        const getRedirectLink = () => {
            if (isIos()) {
                if (isSafari()) {
                    return encodeURIComponent(`${window.location.href}`);
                }

                if (isChrome()) {
                    return encodeURIComponent('googlechrome://');
                }

                if (isFirefox()) {
                    return encodeURIComponent('firefox://');
                }
            }

            return null;
        }

        return {
            userMessage,
            userMessageComponent,
            validationComplete,
            validationError,
            validationUserSignPending,
            validationResume,
            validationCanceled,
            autoStartToken,
            launchLink,
            isAndroid,
            getBankIdLaunchLink,
            launchBankIdAppAndAuth,
            cancelAndRedirect
        }
    }
}
</script>