<template>
    <div class="accordion-text__item" :class="{ 'is-open': !hide }">
        <a :href="'#'+ itemid" @click="toggleShow($event)">
            <slot name="header"></slot>
            <div class="accordion-text__item-toggle" :class="{ 'is-open': !hide }"></div>
        </a>
        <div :id="itemid" class="accordion-text__item-content" :class="{ 'is-closed': hide }">
            <slot name="body"></slot>
        </div>
    </div>
</template>

<script>

import {onMounted, ref} from "vue";

export default {
    props: {
        itemid: {
            type: String,
            required: true
        }
    },
    setup(props) {
        const hide = ref(true);

        function toggleShow(event) {
            hide.value = !hide.value;
            setHash(event);
        }

        function checkHash() {
            if (location.hash !== null) {
                if (location.hash === "#" + props.itemid) {
                    hide.value = false;

                    goToPos(props.itemid);
                }
            }
        }

        function setHash(event) {
            event.preventDefault();

            const obj = event.currentTarget;
            history.pushState({}, '', obj.href);
        }

        function goToPos(item) {
            if (document.getElementById(item) !== null) {
                setTimeout(function () {
                    JumpToHashCenter(item);
                }, 500);
            }
        }

        onMounted(() => {
            checkHash();
        })

        return {
            hide,
            toggleShow,
            setHash
        }
    }
};

window.JumpToHashCenter = function (hashName, offset) {
    const element_to_scroll_to = document.getElementById(hashName);

    if (element_to_scroll_to !== null) {
        element_to_scroll_to.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
    }
};
</script>

