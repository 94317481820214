<template>
    <div>
        <slot name="select" :change="onChanged"></slot>
        <slot name="options" :selected-index="selectedText"></slot>
    </div>
</template>

<script setup lang="ts">

import { ref } from "vue";
    const props = defineProps({
        initText: String
    })

    const selectedText = ref(props.initText);

    const onChanged = ($event: any) => {
        const selectElement  = $event.target;
        const currentIndex = selectElement.selectedIndex;

        selectedText.value = selectElement.options[currentIndex].text;
    }
</script>


