<template>
    <div>
        <div>
            <button type="submit" class="btn btn--large btn-tertiary mr-2"
                    data-ga-event-action="Klick" data-ga-event-label="Aktivera valda förmåner" data-ga-event-page="Kontot validerat" @click="click">
                Gå till Mina sidor
            </button>
        </div>
        <div class="mt-2 ms-lg-1">
            <v-ratsit-checkbox v-slot:default="slotProps">
                <label>
                    <input type="checkbox" id="segmentering" v-model="segmentering" />
                    <span style="border: 1px solid transparent; text-align:left">Ja tack, jag vill ha möjlighet till anpassade tjänster</span>
                </label>
            </v-ratsit-checkbox>
            <p class="site-p--small ms-4 ms-lg-0 text-start"><i>Du kan alltid ändra dina inställningar under <a href="/minasidor" class="text-nowrap">Mina Sidor</a></i></p>
        </div>
    </div>
</template>

<script>
    import { ref } from "vue";
    import axios from "axios";
    import { logErrorToSentry } from "@/src/js/sentry";

    export default {
        setup() {
            const segmentering = ref(true);
            const url = '/api/bank-id/segmentering';

            const click = async () => {
                try {
                    await axios.post(url, {
                        segmentering: segmentering.value
                    });
                } catch (error) {
                    logErrorToSentry(error);
                }

                location.href = '/minasidor'
            }

            return {
                segmentering,
                click
            }
        }
    }
</script>

