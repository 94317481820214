<template>
    <div class="input-search" :class="{ 'input-search--large': !small }">
        <!-- <label for="sok-kommun" class="visually-hidden">Sök kommun</label> -->
        <input id="sok-kommun" ref="input" type="search" aria-label="Sök kommun" placeholder="Sök kommun" @click="inputChange" @input="inputChange" @blur="hideCloseButton" @keyup.enter="doExactSearch()" />
        <div class="input-search__close-button" :class="{ 'is-active': showClosebutton }" title="Rensa sökfältet" @click="clear">
            <i class="icon-close"></i>
        </div>
        <div v-if="loading" class="loader-spinner loader-spinner--small loader-spinner--search d-inline-block me-1">
            <span>Laddar ...</span>
        </div>
    </div>
</template>

<script>
import { defineComponent, onMounted, ref } from "vue";
import autocomplete from 'autocompleter';
import { wordList } from './data/wordList.js';

export default defineComponent({
    props: {
        small: Boolean,
    },
    setup() {
        const selectedResult = ref(false);
        const showClosebutton = ref(false);
        const loading = ref(false);
        const input = ref(null);

        onMounted(async () => {
            init();
        });

        const init = function () {

            autocomplete({
                input: input.value,
                emptyMsg: "Inga exakta matchningar hittades...",
                minLength: 3,
                disableAutoSelect: true,
                preventSubmit: true,
                className: "ratsit",
                fetch: function (text, update) {
                    text = text.toLowerCase();
                    loading.value = true;

                    var suggestions = wordList.filter(n => n.name.toLowerCase().startsWith(text))

                    update(suggestions);
                    loading.value = false;
                },
                render: function (item, currentValue) {
                    var div = document.createElement("div");

                    div.textContent = item.name;
                    return div;
                },
                onSelect: function (item) {
                    selectedResult.value = true;
                    input.value = item.name;

                    location.href = "?kod=" + encodeURI(item.key);
                }
            });
        };

        const hideCloseButton = function () {
            showClosebutton.value = false;
        };

        const clear = function (e) {
            var field = e.currentTarget.previousElementSibling;
            field.value = "";
            field.focus();
        };

        const inputChange = function (e) {
            showClosebutton.value = e.currentTarget.value.length > 0 ? true : false;
        };

        return {
            wordList,
            init,
            hideCloseButton,
            clear,
            inputChange,
            showClosebutton,
            input,
            loading
        }
    }
});
</script>

<style scoped>
.input-search__close-button {
    right: 25px
}
</style>
