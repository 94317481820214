<template>
  <div class="block-show-ranking">
    <div class="block-show-ranking-inner">
            <span class="reset-intrest" @click="reset" style="display:none" v-bind:class="{ 'd-block' : showData }"><i
                class="fa fa-times-circle"></i></span>
      <div v-if="noDataAvailable" class="error-text">
        Tyvärr gick det inte att visa Löneranking just nu. Försök gärna igen senare.
      </div>
      <div v-if="!noDataAvailable">
        <div class="flip-container mt-1"
             v-bind:class="{ 'click' : flipStarted, 'overflow flip-container--shadow' : addOverflow }"
             v-if="showFlip">
          <div class="flipper" ref="flipper" v-bind:class="{ 'zoom' : zoomStarted }">
            <div class="front"></div>
            <div class="back" ref="bgimage1"></div>
          </div>

          <div style="display: none;" v-bind:class="{ 'data-display' : showData }">
            <div class="block-show-ranking__arrow block-show-ranking__arrow--one"><span>Placering</span></div>
            <div class="block-show-ranking__arrow block-show-ranking__arrow--two"><span>Bet.anm.</span></div>
            <div class="block-show-ranking__arrow block-show-ranking__arrow--four"><span>Lön</span></div>
          </div>

          <div v-bind:class="{ 'salary' : showData }" ref="bgimage2"></div>

        </div>
        <div style="display: none; position:relative" v-bind:class="{ 'data-display' : showData }">
          <div class="data data-bottom">
                        <span>
                            Utdrag ur Ratsitkatalogen, periodisk skrift {{ editionSetName }} utgiven {{
                            editionDate
                          }}.<br/> Ansvarig utgivare: Lars Save
                        </span>
          </div>
        </div>
      </div>

      <div class="mt-1 text-center block-show-ranking-disclaimer" v-if="showFlip">
        <small>Denna del ansvarar inte Ratsits redaktion för.</small>
      </div>

      <div class="loader-spinner loader-spinner--show-ranking" v-if="loading"><span>Laddar...</span></div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import {nextTick, onMounted, ref} from "vue";
import {logErrorToSentry} from "@/src/js/sentry";

export default {
  props: {
    encryptedPnr: String,
    editionId: Number,
    editionSetId: Number
  },
  setup(props) {
    const editionSetName = ref('')
    const editionDate = ref('')
    const img = ref(null)
    const pagex = ref(0)
    const pagey = ref(0)
    const showFlip = ref(false)
    const flipStarted = ref(false)
    const zoomStarted = ref(false)
    const showData = ref(false)
    const addOverflow = ref(false)
    const noDataAvailable = ref(false)
    const loading = ref(false)
    const bgimage1 = ref(null)
    const bgimage2 = ref(null)
    const flipper = ref(null)

    const flip = () => {
      if (img && img.value) {
        showFlip.value = true;

        setTimeout(() => {
          flipStarted.value = true;

          setOverflow();
          zoom();
          showRanking();
        }, 1500);
      } else {
        noDataAvailable.value = true;
      }
    }

    const addPulse = () => {
      setTimeout(function () {
        const btn = document.getElementById('lonekoll-btn');
        if (btn) {
          btn.classList.add('pulse-timer-purple');
          btn.classList.remove('btn-secondary');
          btn.classList.add('btn-primary');
        }
      }, 5500);
    }

    const zoom = () => {
      flipper.value.style.cssText = `transform-origin: ${pagex.value / 1.47}px ${pagey.value / 1.47}px;`;
      zoomStarted.value = true;
      setBgImg();
    }

    const setBgImg = () => {
      bgimage1.value.style.cssText = `background-image: url(${img.value});`
      bgimage2.value.style.cssText = `background-image: url(${img.value});`
    }

    const showRanking = () => {
      setTimeout(() => {
        showData.value = true;
      }, 3500);
    }

    const setOverflow = () => {
      setTimeout(() => {
        addOverflow.value = true;
      }, 2000);
    }

    const reset = () => {
      showFlip.value = false;
      flipStarted.value = false;
      zoomStarted.value = false;
      showData.value = false;
      addOverflow.value = false;
      loading.value = false;
    }

    const createDocument = async () => {
      try {
        loading.value = true;

        const createResponse = await axios.post('/loneranking/document',
            {
              EncryptedPnr: props.encryptedPnr,
              EditionId: props.editionId,
              EditionSetId: props.editionSetId
            },
            {
              headers: {
                'Content-Type': 'application/json'
              }
            });

        editionSetName.value = `${createResponse.data.editionName}`;
        editionDate.value = createResponse.data.editionDate;
        pagex.value = createResponse.data.positionX;
        pagey.value = createResponse.data.positionY;

        const getResponse = await axios.get(`/loneranking/document/${createResponse.data.documentId}`,
            {
              responseType: 'blob'
            });

        img.value = URL.createObjectURL(getResponse.data);
        loading.value = false;
        flip();

        if (navigator.userAgent.match(/Android/i) ||
            navigator.userAgent.match(/webOS/i) ||
            navigator.userAgent.match(/iPhone/i) ||
            navigator.userAgent.match(/iPad/i) ||
            navigator.userAgent.match(/iPod/i) ||
            navigator.userAgent.match(/BlackBerry/i) ||
            navigator.userAgent.match(/Windows Phone/i)) {

          setTimeout(() => {
            const buy = document.getElementById('lonekoll-btn')
            buy.scrollIntoView(false)
          }, 5000);
        }

        addPulse();
      } catch (error) {
        noDataAvailable.value = true;
        loading.value = false;
        logErrorToSentry(error);
      }
    }

    onMounted(() => {
      nextTick(async () => {
        await createDocument()
      })
    })

    return {
      editionSetName,
      editionDate,
      img,
      pagex,
      pagey,
      showFlip,
      flipStarted,
      zoomStarted,
      showData,
      addOverflow,
      noDataAvailable,
      loading,
      flip,
      zoom,
      reset,
      bgimage1,
      bgimage2,
      flipper
    }
  }
}
</script>

<style scoped lang="css">
.overflow {
  overflow: hidden;
}

.flip-container {
  position: relative;
  width: 205px;
  height: 289px;
  -webkit-perspective: 1000px;
  -ms-perspective: 1000px;
  perspective: 1000px;
  -ms-transform: perspective(1000px);
  -ms-transform-style: preserve-3d;
  transform-style: preserve-3d;
  margin: 0 auto;
}

.flip-container--shadow {
  box-shadow: 0 1px 13px 0 rgba(190, 204, 211, 0.9);
}

.flipper {
  -webkit-transition: 2s;
  -webkit-transform-style: preserve-3d;
  -ms-transition: 2s;
  -moz-transform: perspective(1000px);
  -ms-transform-style: preserve-3d;
  transition: 2s;
  transform-style: preserve-3d;
  position: relative;
}

.front,
.back {
  -webkit-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: 2s;
  -webkit-transform-style: preserve-3d;
  -webkit-transform: rotateY(0deg);
  -ms-transition: 2s;
  -ms-transform-style: preserve-3d;
  -ms-transform: rotateY(0deg);
  transition: 2s;
  transform-style: preserve-3d;
  transform: rotateY(0deg);
  position: absolute;
  box-shadow: 0 1px 13px 0 rgba(190, 204, 211, 0.9);
}

.front, .back, .salary {
  background-size: cover !important;
  background-repeat: no-repeat;
  width: 205px;
  height: 289px;
}

.front, .back, .salary img {
  width: 205px;
  height: 289px;
}

.front {
  -webkit-transform: rotateY(0deg);
  -ms-transform: rotateY(0deg);
  transform: rotateY(0deg);
  background-position: 0px 0px;
  background-image: url(~root/wwwroot/images/katalog-framsida-ny.png)
}

.back {
  -webkit-transform: rotateY(180deg);
  -ms-transform: rotateY(180deg);
  transform: rotateY(180deg);
  background-position: 0px 0px;
}

.flip-container.click .back {
  -webkit-transform: rotateY(0deg);
  -ms-transform: rotateY(0deg);
  transform: rotateY(0deg);
}

.flip-container.click .front {
  -webkit-transform: rotateY(-180deg);
  -ms-transform: rotateY(-180deg);
  transform: rotateY(-180deg);
}

.zoom {
  width: 205px;
  height: 289px;
  position: relative;
  -webkit-user-select: none;
  -webkit-animation: zoomin 1s ease-in;
  animation: zoomin ease-in 1s 1;
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

@keyframes zoomin {
  from {
    transform: scale(1);
  }

  to {
    transform: scale(3.8);
  }
}

@-webkit-keyframes zoomin {
  from {
    -webkit-transform: scale(1);
  }

  to {
    -webkit-transform: scale(3.8);
  }
}

.salary {
  z-index: 1000;
  position: absolute;
  font-size: 12px;
  padding: 5px;
  background-color: #fff;
  width: 100%;
  text-align: center;
  top: 140px;
  height: 14px;
  transform: scale(2.5);
  background-position: -67px 2px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
}

.data-display {
  display: block !important;
  color: #fff;
}

.data {
  position: absolute;
  font-size: 13px;
  bottom: 0;
  padding: 10px;
  background: #22659d;
  width: 100%;
}

.data-bottom {
  bottom: -35px;
}

.reset-intrest {
  position: absolute;
  right: 0;
  top: 0;
  padding: 15px;
  margin: -5px;
  cursor: pointer;
  font-size: 21px;
}

.error-text {
  text-align: center;
  background-color: #e6f0f7;
  font-size: 13px;
  padding: 5px;
  margin-top: 15px;
}

.loader-spinner {
  position: relative;
  text-align: center;
}

.loader-spinner--show-ranking {
  margin: 0 auto;
  position: absolute;
  left: 45%;
  top: inherit;
}

@media (max-width: 425px) {
  .loader-spinner--show-ranking {
    bottom: -20px;
  }
}

.block-show-ranking {
  z-index: 3;
  position: relative;
}

.block-show-ranking-inner {
  position: relative;
  transition: .3s;
  min-height: 330px;
}


.block-show-ranking__arrow {
  position: absolute;
  top: 0;
  height: 128px;
  width: 57px;
  overflow: hidden;
  font-size: 17px;
}

.block-show-ranking__arrow > span {
  position: absolute;
  left: 52px;
  top: 28px;
  height: 100px;
  width: 30px;
  display: block;
  text-transform: uppercase;
  transform: rotate(270deg);
}

.block-show-ranking__arrow:after {
  content: "";
  position: absolute;
  border-bottom: 40px solid transparent;
  border-left: 40px solid transparent;
  border-right: 40px solid #5492c5;
  top: 64px;
  left: 3px;
  transform: rotate(135deg);
}

.block-show-ranking__arrow:before {
  content: "";
  position: absolute;
  width: 30px;
  height: 100px;
  background-color: #5492c5;
  top: 0;
  left: 14px;
}

.block-show-ranking__arrow--one {
  left: 0;
  z-index: 1;
}

.block-show-ranking__arrow--one:after {
  border-right: 40px solid #4933aa;
}

.block-show-ranking__arrow--one:before {
  background-color: #4933aa;
}


.block-show-ranking__arrow--two {
  left: 61px;
}

.block-show-ranking__arrow--three {
  left: 205px;
}

.block-show-ranking__arrow--four {
  left: 131px;
}

.block-show-ranking-disclaimer {
  position: absolute;
  bottom: -27px;
  width: 100%;
  left: 0;
  text-align: center;
}

.block-show-ranking-disclaimer small {
  font-size: 13px;
}
</style>