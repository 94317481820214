<template>
    <div>
        <slot v-if="selectedText === text"></slot>
    </div>
</template>

<script>
    export default {
        props: {
            selectedText: String,
            text: String
        }
    }
</script>