<template>
    <div>
        <div class="input-field input-field--large mt-2">
            <div class="input-search input-search--large">
                <!-- <label for="sok-kommun" class="visually-hidden">Sök kommun</label> -->
                <input id="sok-kommun" ref="input" aria-label="Sök kommun" type="search" placeholder="Sök kommun" @click="inputChange" @input="inputChange" />
                <div class="input-search__close-button" :class="{ 'is-active': showClosebutton }" title="Rensa sökfältet" @click="clear">
                    <i class="icon-close"></i>
                </div>
                <!--<button type="submit" value="Sök" aria-label="Sök"><span class="visually-hidden">Sök</span><i class="icon-search"></i></button>-->
                <div v-if="loading" class="loader-spinner loader-spinner--small loader-spinner--search d-inline-block me-1">
                    <span>Laddar ...</span>
                </div>
            </div>
        </div>

        <div class="mt-2 text-center">
            <button type="submit" class="btn btn--large btn--full-width btn-primary">Sök katalog</button>
        </div>
    </div>
</template>

<script>
import { defineComponent, onMounted, ref } from "vue";
import autocomplete from 'autocompleter';
import axios from 'axios';


export default defineComponent({
    setup() {
        const selectedResult = ref(false);
        const showClosebutton = ref(false);
        const loading = ref(false);
        const input = ref(null);
        const productList = ref([]);

        onMounted(async () => {
            axios.get("/ratsitkatalogen/editioner.json").then(res => {
                productList.value = res.data;
                init();
            });
        });

        const init = function () {
            autocomplete({
                input: input.value,
                emptyMsg: "Inga exakta matchningar hittades...",
                minLength: 3,
                disableAutoSelect: true,
                preventSubmit: true,
                className: "ratsit",
                fetch: function (text, update) {
                    text = text.toLowerCase();
                    loading.value = true;

                    var suggestions = productList.value.filter(n => n.description.toLowerCase().includes(text))
                    update(suggestions);
                    loading.value = false;

                },
                render: function (item, currentValue) {
                    var div = document.createElement("div");

                    div.textContent = item.description;
                    return div;
                },
                onSelect: function (item) {

                    selectedResult.value = true;
                    input.value = item.name;

                    window.location.href = `/ratsitkatalogen/produkt/${encodeURI(item.productId)}`;
                }
            });
        };

        const hideCloseButton = function () {
            showClosebutton.value = false;
        };

        const clear = function (e) {
            var field = e.currentTarget.previousElementSibling;
            field.value = "";
            field.focus();
        };

        //const focusClear = function (e) {
        //    input.value.value = "";
        //};

        const inputChange = function (e) {
            showClosebutton.value = e.currentTarget.value.length > 0 ? true : false;
        };

        return {
            productList,
            init,
            hideCloseButton,
            clear,
            //focusClear,
            inputChange,
            showClosebutton,
            input,
            loading
        }
    }
});
</script>

<style scoped>
.input-search__close-button {
    right: 25px
}
</style>
