export default {
    mounted(el) {
        el.addEventListener('click', scrollOnClick);
    }
}

function scrollOnClick(e) {
    e.preventDefault();
    window.scrollTo({
        behavior: 'smooth',
        left: 0,
        top: 0
    });
}
