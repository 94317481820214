import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { ref } from "vue";
    
export default /*@__PURE__*/_defineComponent({
  __name: 'v-rule-automations-wrapper',
  props: {
        initText: String
    },
  setup(__props) {


const props = __props

    const selectedText = ref(props.initText);

    const onChanged = ($event: any) => {
        const selectElement  = $event.target;
        const currentIndex = selectElement.selectedIndex;

        selectedText.value = selectElement.options[currentIndex].text;
    }

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _renderSlot(_ctx.$slots, "select", { change: onChanged }),
    _cache[0] || (_cache[0] = _createTextVNode()),
    _renderSlot(_ctx.$slots, "options", { selectedIndex: selectedText.value })
  ]))
}
}

})