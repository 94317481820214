<template>
    <div>
        <div ref="customScript"></div>
    </div>
</template>

<script>
import { onMounted, ref } from "vue";

export default {
    props: {
        source: {
            type: String,
            required: true
        },
        id: {
            type: String,
            required: true
        }
    },
    setup(props) {
        const customScript = ref(null);

        onMounted(() => {
            const newScriptTag = document.createElement("script");
            newScriptTag.type = "text/javascript";
            newScriptTag.src = props.source;
            newScriptTag.async = true;
            newScriptTag.id = props.id;

            customScript.value.appendChild(newScriptTag);
        });

        return {
            customScript
        }
    }
}
</script>

