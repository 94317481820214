import "root/src/scss/default.scss";

import "core-js/stable";
import "regenerator-runtime/runtime";

import 'root/src/js/shared/outline.js';

import { createApp } from 'vue'

const app = createApp({});

import SearchFieldComponent from "root/src/js/shared/v-input-search.vue";
app.component('v-input-search', SearchFieldComponent);

import AccordionTextItem from "root/src/js/shared/v-accordion-text-item.vue";
app.component('v-accordion-text-item', AccordionTextItem);

import TourButton from 'root/src/js/shared/v-tour-button.vue';
app.component('v-tour-button', TourButton);

import DevToolbar from 'root/src/js/shared/v-dev-toobar.vue';
app.component('v-dev-toolbar', DevToolbar);

import LayoutPlugin from 'root/src/js/shared/layout/layout-plugin';
app.use(LayoutPlugin);

import Toggle from 'root/src/js/shared/v-content-toggle.vue';
app.component('v-content-toggle', Toggle);

import Modal from 'root/src/js/shared/v-modal.vue';
app.component('v-modal', Modal);

import ScaleThisAd from 'root/src/js/shared/v-scale-this-ad.js';
app.directive('scale-this-ad', ScaleThisAd);

import SmoothScroll from 'root/src/js/shared/v-smoothscroll.js';
app.directive('smoothscroll', SmoothScroll);

import BankIdPlugin from "root/Pages/Shared/BankId/Components/bank-id-plugin";
app.use(BankIdPlugin);

import ShowRanking from "root/Pages/Loneranking/components/v-show-ranking.vue";
app.component('v-show-ranking', ShowRanking);

import AutocompleteKatalogKommun from "root/Pages/Katalog/components/v-autocomplete-katalog-kommun.vue";
app.component('v-autocomplete-katalog-kommun', AutocompleteKatalogKommun);

import AutocompleteMostWantedKommun from 'root/Pages/Storyblok/MostWanted/components/v-autocomplete-mostwanted-kommun.vue';
app.component('v-autocomplete-mostwanted-kommun', AutocompleteMostWantedKommun);

import SmoothScrollTop from 'root/src/js/shared/v-smoothscroll-top.js';
app.directive('smoothscrolltop', SmoothScrollTop);

import ScriptWrapper from 'root/src/js/shared/v-script-wrapper.vue';
app.component('v-script-wrapper', ScriptWrapper);

import CheckboxComponent from "root/src/js/shared/v-ratsit-checkbox.vue";
app.component('v-ratsit-checkbox', CheckboxComponent);

import CheckboxToggleComponent from "root/src/js/shared/v-ratsit-checkbox-toggle.vue";
app.component('v-ratsit-checkbox-toggle', CheckboxToggleComponent);

import PlaygroundPlugin from "root/Pages/Playground/components/playground-plugin";
app.use(PlaygroundPlugin);

import TurnstileChallenge from "root/Pages/Shared/Turnstile/v-turnstile-challenge.vue";
app.component('v-turnstile-challenge', TurnstileChallenge);

app.mount('#app');