export const wordList = [
    {
        "key": 1440,
        "name": "Ale"
    },
    {
        "key": 1489,
        "name": "Alingsås"
    },
    {
        "key": "0764",
        "name": "Alvesta"
    },
    {
        "key": "0604",
        "name": "Aneby"
    },
    {
        "key": 1984,
        "name": "Arboga"
    },
    {
        "key": 2506,
        "name": "Arjeplog"
    },
    {
        "key": 2505,
        "name": "Arvidsjaur"
    },
    {
        "key": 1784,
        "name": "Arvika"
    },
    {
        "key": 1882,
        "name": "Askersund"
    },
    {
        "key": 2084,
        "name": "Avesta"
    },
    {
        "key": 1460,
        "name": "Bengtsfors"
    },
    {
        "key": 2326,
        "name": "Berg"
    },
    {
        "key": 2403,
        "name": "Bjurholm"
    },
    {
        "key": 1260,
        "name": "Bjuv"
    },
    {
        "key": 2582,
        "name": "Boden"
    },
    {
        "key": 1443,
        "name": "Bollebygd"
    },
    {
        "key": 2183,
        "name": "Bollnäs"
    },
    {
        "key": "0885",
        "name": "Borgholm"
    },
    {
        "key": 2081,
        "name": "Borlänge"
    },
    {
        "key": 1490,
        "name": "Borås"
    },
    {
        "key": "0127",
        "name": "Botkyrka"
    },
    {
        "key": "0560",
        "name": "Boxholm"
    },
    {
        "key": 1272,
        "name": "Bromölla"
    },
    {
        "key": 2305,
        "name": "Bräcke"
    },
    {
        "key": 1231,
        "name": "Burlöv"
    },
    {
        "key": 1278,
        "name": "Båstad"
    },
    {
        "key": 1438,
        "name": "Dals-Ed"
    },
    {
        "key": "0162",
        "name": "Danderyd"
    },
    {
        "key": 1862,
        "name": "Degerfors"
    },
    {
        "key": 2425,
        "name": "Dorotea"
    },
    {
        "key": 1730,
        "name": "Eda"
    },
    {
        "key": "0125",
        "name": "Ekerö"
    },
    {
        "key": "0686",
        "name": "Eksjö"
    },
    {
        "key": "0862",
        "name": "Emmaboda"
    },
    {
        "key": "0381",
        "name": "Enköping"
    },
    {
        "key": "0484",
        "name": "Eskilstuna"
    },
    {
        "key": 1285,
        "name": "Eslöv"
    },
    {
        "key": 1445,
        "name": "Essunga"
    },
    {
        "key": 1982,
        "name": "Fagersta"
    },
    {
        "key": 1382,
        "name": "Falkenberg"
    },
    {
        "key": 1499,
        "name": "Falköping"
    },
    {
        "key": 2080,
        "name": "Falun"
    },
    {
        "key": 1782,
        "name": "Filipstad"
    },
    {
        "key": "0562",
        "name": "Finspång"
    },
    {
        "key": "0482",
        "name": "Flen"
    },
    {
        "key": 1763,
        "name": "Forshaga"
    },
    {
        "key": 1439,
        "name": "Färgelanda"
    },
    {
        "key": 2026,
        "name": "Gagnef"
    },
    {
        "key": "0662",
        "name": "Gislaved"
    },
    {
        "key": "0461",
        "name": "Gnesta"
    },
    {
        "key": "0617",
        "name": "Gnosjö"
    },
    {
        "key": "0980",
        "name": "Gotland"
    },
    {
        "key": 1764,
        "name": "Grums"
    },
    {
        "key": 1444,
        "name": "Grästorp"
    },
    {
        "key": 1447,
        "name": "Gullspång"
    },
    {
        "key": 2523,
        "name": "Gällivare"
    },
    {
        "key": 2180,
        "name": "Gävle"
    },
    {
        "key": 1480,
        "name": "Göteborg"
    },
    {
        "key": 1471,
        "name": "Götene"
    },
    {
        "key": "0643",
        "name": "Habo"
    },
    {
        "key": 1783,
        "name": "Hagfors"
    },
    {
        "key": 1861,
        "name": "Hallsberg"
    },
    {
        "key": 1961,
        "name": "Hallstahammar"
    },
    {
        "key": 1380,
        "name": "Halmstad"
    },
    {
        "key": 1761,
        "name": "Hammarö"
    },
    {
        "key": "0136",
        "name": "Haninge"
    },
    {
        "key": 2583,
        "name": "Haparanda"
    },
    {
        "key": "0331",
        "name": "Heby"
    },
    {
        "key": 2083,
        "name": "Hedemora"
    },
    {
        "key": 1283,
        "name": "Helsingborg"
    },
    {
        "key": 1466,
        "name": "Herrljunga"
    },
    {
        "key": 1497,
        "name": "Hjo"
    },
    {
        "key": 2104,
        "name": "Hofors"
    },
    {
        "key": "0126",
        "name": "Huddinge"
    },
    {
        "key": 2184,
        "name": "Hudiksvall"
    },
    {
        "key": "0860",
        "name": "Hultsfred"
    },
    {
        "key": 1315,
        "name": "Hylte"
    },
    {
        "key": "0305",
        "name": "Håbo"
    },
    {
        "key": 1863,
        "name": "Hällefors"
    },
    {
        "key": 2361,
        "name": "Härjedalen"
    },
    {
        "key": 2280,
        "name": "Härnösand"
    },
    {
        "key": 1401,
        "name": "Härryda"
    },
    {
        "key": 1293,
        "name": "Hässleholm"
    },
    {
        "key": 1284,
        "name": "Höganäs"
    },
    {
        "key": "0821",
        "name": "Högsby"
    },
    {
        "key": 1266,
        "name": "Hörby"
    },
    {
        "key": 1267,
        "name": "Höör"
    },
    {
        "key": 2510,
        "name": "Jokkmokk"
    },
    {
        "key": "0123",
        "name": "Järfälla"
    },
    {
        "key": "0680",
        "name": "Jönköping"
    },
    {
        "key": 2514,
        "name": "Kalix"
    },
    {
        "key": "0880",
        "name": "Kalmar"
    },
    {
        "key": 1446,
        "name": "Karlsborg"
    },
    {
        "key": 1082,
        "name": "Karlshamn"
    },
    {
        "key": 1883,
        "name": "Karlskoga"
    },
    {
        "key": 1080,
        "name": "Karlskrona"
    },
    {
        "key": 1780,
        "name": "Karlstad"
    },
    {
        "key": "0483",
        "name": "Katrineholm"
    },
    {
        "key": 1715,
        "name": "Kil"
    },
    {
        "key": "0513",
        "name": "Kinda"
    },
    {
        "key": 2584,
        "name": "Kiruna"
    },
    {
        "key": 1276,
        "name": "Klippan"
    },
    {
        "key": "0330",
        "name": "Knivsta"
    },
    {
        "key": 2282,
        "name": "Kramfors"
    },
    {
        "key": 1290,
        "name": "Kristianstad"
    },
    {
        "key": 1781,
        "name": "Kristinehamn"
    },
    {
        "key": 2309,
        "name": "Krokom"
    },
    {
        "key": 1881,
        "name": "Kumla"
    },
    {
        "key": 1384,
        "name": "Kungsbacka"
    },
    {
        "key": 1960,
        "name": "Kungsör"
    },
    {
        "key": 1482,
        "name": "Kungälv"
    },
    {
        "key": 1261,
        "name": "Kävlinge"
    },
    {
        "key": 1983,
        "name": "Köping"
    },
    {
        "key": 1381,
        "name": "Laholm"
    },
    {
        "key": 1282,
        "name": "Landskrona"
    },
    {
        "key": 1860,
        "name": "Laxå"
    },
    {
        "key": 1814,
        "name": "Lekeberg"
    },
    {
        "key": 2029,
        "name": "Leksand"
    },
    {
        "key": 1441,
        "name": "Lerum"
    },
    {
        "key": "0761",
        "name": "Lessebo"
    },
    {
        "key": "0186",
        "name": "Lidingö"
    },
    {
        "key": 1494,
        "name": "Lidköping"
    },
    {
        "key": 1462,
        "name": "Lilla Edet"
    },
    {
        "key": 1885,
        "name": "Lindesberg"
    },
    {
        "key": "0580",
        "name": "Linköping"
    },
    {
        "key": "0781",
        "name": "Ljungby"
    },
    {
        "key": 2161,
        "name": "Ljusdal"
    },
    {
        "key": 1864,
        "name": "Ljusnarsberg"
    },
    {
        "key": 1262,
        "name": "Lomma"
    },
    {
        "key": 2085,
        "name": "Ludvika"
    },
    {
        "key": 2580,
        "name": "Luleå"
    },
    {
        "key": 1281,
        "name": "Lund"
    },
    {
        "key": 2481,
        "name": "Lycksele"
    },
    {
        "key": 1484,
        "name": "Lysekil"
    },
    {
        "key": 1280,
        "name": "Malmö"
    },
    {
        "key": 2023,
        "name": "Malung-Sälen"
    },
    {
        "key": 2418,
        "name": "Malå"
    },
    {
        "key": 1493,
        "name": "Mariestad"
    },
    {
        "key": 1463,
        "name": "Mark"
    },
    {
        "key": "0767",
        "name": "Markaryd"
    },
    {
        "key": 1461,
        "name": "Mellerud"
    },
    {
        "key": "0586",
        "name": "Mjölby"
    },
    {
        "key": 2062,
        "name": "Mora"
    },
    {
        "key": "0583",
        "name": "Motala"
    },
    {
        "key": "0642",
        "name": "Mullsjö"
    },
    {
        "key": 1430,
        "name": "Munkedal"
    },
    {
        "key": 1762,
        "name": "Munkfors"
    },
    {
        "key": 1481,
        "name": "Mölndal"
    },
    {
        "key": "0861",
        "name": "Mönsterås"
    },
    {
        "key": "0840",
        "name": "Mörbylånga"
    },
    {
        "key": "0182",
        "name": "Nacka"
    },
    {
        "key": 1884,
        "name": "Nora"
    },
    {
        "key": 1962,
        "name": "Norberg"
    },
    {
        "key": 2132,
        "name": "Nordanstig"
    },
    {
        "key": 2401,
        "name": "Nordmaling"
    },
    {
        "key": "0581",
        "name": "Norrköping"
    },
    {
        "key": "0188",
        "name": "Norrtälje"
    },
    {
        "key": 2417,
        "name": "Norsjö"
    },
    {
        "key": "0881",
        "name": "Nybro"
    },
    {
        "key": "0140",
        "name": "Nykvarn"
    },
    {
        "key": "0480",
        "name": "Nyköping"
    },
    {
        "key": "0192",
        "name": "Nynäshamn"
    },
    {
        "key": "0682",
        "name": "Nässjö"
    },
    {
        "key": 2101,
        "name": "Ockelbo"
    },
    {
        "key": 1060,
        "name": "Olofström"
    },
    {
        "key": 2034,
        "name": "Orsa"
    },
    {
        "key": 1421,
        "name": "Orust"
    },
    {
        "key": 1273,
        "name": "Osby"
    },
    {
        "key": "0882",
        "name": "Oskarshamn"
    },
    {
        "key": 2121,
        "name": "Ovanåker"
    },
    {
        "key": "0481",
        "name": "Oxelösund"
    },
    {
        "key": 2521,
        "name": "Pajala"
    },
    {
        "key": 1402,
        "name": "Partille"
    },
    {
        "key": 1275,
        "name": "Perstorp"
    },
    {
        "key": 2581,
        "name": "Piteå"
    },
    {
        "key": 2303,
        "name": "Ragunda"
    },
    {
        "key": 2409,
        "name": "Robertsfors"
    },
    {
        "key": 1081,
        "name": "Ronneby"
    },
    {
        "key": 2031,
        "name": "Rättvik"
    },
    {
        "key": 1981,
        "name": "Sala"
    },
    {
        "key": "0128",
        "name": "Salem"
    },
    {
        "key": 2181,
        "name": "Sandviken"
    },
    {
        "key": "0191",
        "name": "Sigtuna"
    },
    {
        "key": 1291,
        "name": "Simrishamn"
    },
    {
        "key": 1265,
        "name": "Sjöbo"
    },
    {
        "key": 1495,
        "name": "Skara"
    },
    {
        "key": 2482,
        "name": "Skellefteå"
    },
    {
        "key": 1904,
        "name": "Skinnskatteberg"
    },
    {
        "key": 1264,
        "name": "Skurup"
    },
    {
        "key": 1496,
        "name": "Skövde"
    },
    {
        "key": 2061,
        "name": "Smedjebacken"
    },
    {
        "key": 2283,
        "name": "Sollefteå"
    },
    {
        "key": "0163",
        "name": "Sollentuna"
    },
    {
        "key": "0184",
        "name": "Solna"
    },
    {
        "key": 2422,
        "name": "Sorsele"
    },
    {
        "key": 1427,
        "name": "Sotenäs"
    },
    {
        "key": 1230,
        "name": "Staffanstorp"
    },
    {
        "key": 1415,
        "name": "Stenungsund"
    },
    {
        "key": "0180",
        "name": "Stockholm"
    },
    {
        "key": 1760,
        "name": "Storfors"
    },
    {
        "key": 2421,
        "name": "Storuman"
    },
    {
        "key": "0486",
        "name": "Strängnäs"
    },
    {
        "key": 1486,
        "name": "Strömstad"
    },
    {
        "key": 2313,
        "name": "Strömsund"
    },
    {
        "key": "0183",
        "name": "Sundbyberg"
    },
    {
        "key": 2281,
        "name": "Sundsvall"
    },
    {
        "key": 1766,
        "name": "Sunne"
    },
    {
        "key": 1907,
        "name": "Surahammar"
    },
    {
        "key": 1214,
        "name": "Svalöv"
    },
    {
        "key": 1263,
        "name": "Svedala"
    },
    {
        "key": 1465,
        "name": "Svenljunga"
    },
    {
        "key": 1785,
        "name": "Säffle"
    },
    {
        "key": 2082,
        "name": "Säter"
    },
    {
        "key": "0684",
        "name": "Sävsjö"
    },
    {
        "key": 2182,
        "name": "Söderhamn"
    },
    {
        "key": "0582",
        "name": "Söderköping"
    },
    {
        "key": "0181",
        "name": "Södertälje"
    },
    {
        "key": 1083,
        "name": "Sölvesborg"
    },
    {
        "key": 1435,
        "name": "Tanum"
    },
    {
        "key": 1472,
        "name": "Tibro"
    },
    {
        "key": 1498,
        "name": "Tidaholm"
    },
    {
        "key": "0360",
        "name": "Tierp"
    },
    {
        "key": 2262,
        "name": "Timrå"
    },
    {
        "key": "0763",
        "name": "Tingsryd"
    },
    {
        "key": 1419,
        "name": "Tjörn"
    },
    {
        "key": 1270,
        "name": "Tomelilla"
    },
    {
        "key": 1737,
        "name": "Torsby"
    },
    {
        "key": "0834",
        "name": "Torsås"
    },
    {
        "key": 1452,
        "name": "Tranemo"
    },
    {
        "key": "0687",
        "name": "Tranås"
    },
    {
        "key": 1287,
        "name": "Trelleborg"
    },
    {
        "key": 1488,
        "name": "Trollhättan"
    },
    {
        "key": "0488",
        "name": "Trosa"
    },
    {
        "key": "0138",
        "name": "Tyresö"
    },
    {
        "key": "0160",
        "name": "Täby"
    },
    {
        "key": 1473,
        "name": "Töreboda"
    },
    {
        "key": 1485,
        "name": "Uddevalla"
    },
    {
        "key": 1491,
        "name": "Ulricehamn"
    },
    {
        "key": 2480,
        "name": "Umeå"
    },
    {
        "key": "0114",
        "name": "Upplands Väsby"
    },
    {
        "key": "0139",
        "name": "Upplands-Bro"
    },
    {
        "key": "0380",
        "name": "Uppsala"
    },
    {
        "key": "0760",
        "name": "Uppvidinge"
    },
    {
        "key": "0584",
        "name": "Vadstena"
    },
    {
        "key": "0665",
        "name": "Vaggeryd"
    },
    {
        "key": "0563",
        "name": "Valdemarsvik"
    },
    {
        "key": "0115",
        "name": "Vallentuna"
    },
    {
        "key": 2021,
        "name": "Vansbro"
    },
    {
        "key": 1470,
        "name": "Vara"
    },
    {
        "key": 1383,
        "name": "Varberg"
    },
    {
        "key": "0187",
        "name": "Vaxholm"
    },
    {
        "key": 1233,
        "name": "Vellinge"
    },
    {
        "key": "0685",
        "name": "Vetlanda"
    },
    {
        "key": 2462,
        "name": "Vilhelmina"
    },
    {
        "key": "0884",
        "name": "Vimmerby"
    },
    {
        "key": 2404,
        "name": "Vindeln"
    },
    {
        "key": "0428",
        "name": "Vingåker"
    },
    {
        "key": 1442,
        "name": "Vårgårda"
    },
    {
        "key": 1487,
        "name": "Vänersborg"
    },
    {
        "key": 2460,
        "name": "Vännäs"
    },
    {
        "key": "0120",
        "name": "Värmdö"
    },
    {
        "key": "0683",
        "name": "Värnamo"
    },
    {
        "key": "0883",
        "name": "Västervik"
    },
    {
        "key": 1980,
        "name": "Västerås"
    },
    {
        "key": "0780",
        "name": "Växjö"
    },
    {
        "key": "0512",
        "name": "Ydre"
    },
    {
        "key": 1286,
        "name": "Ystad"
    },
    {
        "key": 1492,
        "name": "Åmål"
    },
    {
        "key": 2260,
        "name": "Ånge"
    },
    {
        "key": 2321,
        "name": "Åre"
    },
    {
        "key": 1765,
        "name": "Årjäng"
    },
    {
        "key": 2463,
        "name": "Åsele"
    },
    {
        "key": 1277,
        "name": "Åstorp"
    },
    {
        "key": "0561",
        "name": "Åtvidaberg"
    },
    {
        "key": "0765",
        "name": "Älmhult"
    },
    {
        "key": 2039,
        "name": "Älvdalen"
    },
    {
        "key": "0319",
        "name": "Älvkarleby"
    },
    {
        "key": 2560,
        "name": "Älvsbyn"
    },
    {
        "key": 1292,
        "name": "Ängelholm"
    },
    {
        "key": 1407,
        "name": "Öckerö"
    },
    {
        "key": "0509",
        "name": "Ödeshög"
    },
    {
        "key": 1880,
        "name": "Örebro"
    },
    {
        "key": 1257,
        "name": "Örkelljunga"
    },
    {
        "key": 2284,
        "name": "Örnsköldsvik"
    },
    {
        "key": 2380,
        "name": "Östersund"
    },
    {
        "key": "0117",
        "name": "Österåker"
    },
    {
        "key": "0382",
        "name": "Östhammar"
    },
    {
        "key": 1256,
        "name": "Östra Göinge"
    },
    {
        "key": 2513,
        "name": "Överkalix"
    },
    {
        "key": 2518,
        "name": "Övertorneå"
    }
];