import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderSlot as _renderSlot, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", {
      class: _normalizeClass({ 'hide': !_ctx.isChallengeInteractive, 'mt-2': _ctx.isChallengeInteractive }),
      id: "turnstile-container"
    }, null, 2 /* CLASS */),
    _cache[0] || (_cache[0] = _createTextVNode()),
    _renderSlot(_ctx.$slots, "default", {
      isChallengeExecuted: _ctx.isChallengeExecuted,
      isChallengeInteractive: _ctx.isChallengeInteractive
    }, undefined, true)
  ]))
}