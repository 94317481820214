<template>
    <div class="col-12 col-xl-10 offset-xl-1">
        <div class="mt-3">
            <div v-show="error">
                <div id="error" class="info-box validation-summary-errors">
                    <ul>
                        <li>
                            {{ message }}
                        </li>
                    </ul>
                </div>
            </div>

            <div class="block-info-text" v-show="showCreatedUser">
                <h4>{{ message }}</h4>
                <div id="created-user" v-if="createdUser">
                    <ul>
                        <li v-if="isGeneratorEmail">Email: <a :href="generatorEmailLink">{{ createdUser.email }}</a>
                            <button class="btn btn-tertiary" v-on:click="copyToClipboard(createdUser.email)">Kopiera</button>
                        </li>
                        <li v-else>Email: {{ createdUser.email }}</li>

                        <li>Aktiverad: {{ createdUser.activated }}</li>
                        <li>Validerad: {{ createdUser.validated }}</li>
                        <li>Plus: {{ createdUser.plus }}</li>
                        <li>Kön: {{ createdUser.gender }}</li>
                        <li>Personnummer: {{ createdUser.pNr }}</li>
                        <li>Förnamn: {{ createdUser.fName }}</li>
                        <li>Efternamn: {{ createdUser.lName }}</li>
                        <li>Lösenord: {{ createdUser.password }}
                            <button class="btn btn-tertiary" v-on:click="copyToClipboard(createdUser.password)">Kopiera</button>
                        </li>
                        <li>Organisatonsnummer: {{ createdUser.orgNr }}</li>
                        <li>Företagsnamn: {{ createdUser.orgName }}</li>
                        <li>Adress: {{ createdUser.adr }}</li>
                        <li>Postnummer: {{ createdUser.postNr }}</li>
                        <li>Stad: {{ createdUser.city }}</li>
                        <li>Telefonnummer: {{ createdUser.teleNr }}</li>
                        <li>Saldo Ratsitkonto: {{ createdUser.ratsitPoints }}</li>
                    </ul>
                    <button class="btn btn-primary" v-on:click="signInTestUser">Logga in med denna användare</button>
                </div>
            </div>

            <div class="block__header">
                <h1>Skapa användare för Test</h1>
                <p>Skapa användare utan att själva behöva gå igenom alla steg för detta. Använd standard-inställningarna
                    genom att direkt skapa en användare nedan eller klicka Avancerat för att själv ställa in olika
                    detaljer.</p>
                <p>Observera att om du väljer ett slumpat/fejkat personnummer finns det funktioner i Ratsit som <strong>inte</strong>
                    kommer att fungera (ex Löneranking och Självbevakning).</p>
            </div>

            <v-content-toggle v-slot:default="slotProps" class="mt-3">
                <p class="read-more mt-2 user-select-auto" v-on:click="slotProps.toggleShowHide($event)">
                    <strong><i class="icon-settings-cog-outline" style="position:relative; top: 2px; margin-right: 5px"></i>Avancerat</strong>
                    <span class="read-more__toggle ms-2" v-bind:class="{ 'is-active' : slotProps.isVisible }"></span>
                </p>

                <div style="display:none" v-bind:class="{ 'd-block' : slotProps.isVisible }">
                    <div id="advanced-container">
                        <div class="block__section">
                            <h2 class="mt-3 mt-lg-4">Avancerade inställningar - standard används om inget sätts här</h2>
                            <p>
                                De här inställningarna behöver du bara ändra om du själv vill sätta det till något
                                specifikt. Annars kommer standard-inställningar att väljas. Vad som valts får du reda på
                                efter du skapat användaren.
                            </p>
                            <div class="mt-2">
                                <div class="form-horizontal">
                                    <div class="row">
                                        <div class="col-sm-12 col-lg-6 form-group form-group-dash mt-1">
                                            <label class="control-label">Kön</label>&nbsp;<span class="tooltip"><i
                                            class="icon-info"></i><span class="tooltip-text tooltip-text--small"
                                                                        style="z-index:100">Välj kön, används ej om Personnumer väljs dock</span></span>
                                            <div class="input-select">
                                                <select class="form-control form-control-dashboard"
                                                        v-model="testUser.gender">
                                                    <option value="slump">Slump</option>
                                                    <option value="man">Man</option>
                                                    <option value="kvinna">Kvinna</option>
                                                </select>
                                            </div>
                                        </div>

                                        <div class="col-sm-12 col-lg-6 form-group form-group-dash mt-1">
                                            <label class="control-label">Personnummer</label>&nbsp;<span
                                            class="tooltip"><i class="icon-info"></i><span class="tooltip-text">Skriv in ett personnummer om du vill ha ett specifikt, 12 tecken, slumpas annars. Innebär att Löneranking och Självbevakning kanske inte fungerar om PNr inte finns på riktigt.</span></span>
                                            <div class="input-field">
                                                <input class="form-control form-control-dashboard" type="text"
                                                       placeholder="Personnummer slumpas (YYYYMMDD-XXXX)"
                                                       v-model="testUser.pNr">
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-sm-12 col-lg-6 form-group form-group-dash mt-2">
                                            <label class="control-label">Förnamn</label>&nbsp;<span class="tooltip"><i
                                            class="icon-info"></i><span class="tooltip-text">Om fältet lämnas tomt slumpas namnet</span></span>
                                            <div class="input-field">
                                                <input class="form-control form-control-dashboard" type="text"
                                                       placeholder="Förnamn slumpas" v-model="testUser.fName">
                                            </div>
                                        </div>

                                        <div class="col-sm-12 col-lg-6 form-group form-group-dash mt-2">
                                            <label class="control-label">Efternamn</label>&nbsp;<span class="tooltip"><i
                                            class="icon-info"></i><span class="tooltip-text">Om fältet lämnas tomt slumpas namnet</span></span>
                                            <div class="input-field">
                                                <input class="form-control form-control-dashboard" type="text"
                                                       placeholder="Efternamn slumpas" v-model="testUser.lName">
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-sm-12 col-lg-6 form-group form-group-dash mt-2">
                                            <label class="control-label">OrgNamn</label>&nbsp;<span class="tooltip"><i
                                            class="icon-info"></i><span class="tooltip-text">Om fältet lämnas tomt sätts det till Invativas</span></span>
                                            <div class="input-field">
                                                <input class="form-control form-control-dashboard" type="text"
                                                       placeholder="Orgnamn sätts till Invativa"
                                                       v-model="testUser.orgName">
                                            </div>
                                        </div>

                                        <div class="col-sm-12 col-lg-6 form-group form-group-dash mt-2">
                                            <label class="control-label">OrgNr</label>&nbsp;<span class="tooltip"><i
                                            class="icon-info"></i><span class="tooltip-text">Om fältet lämnas tomt sätts det till Invativas organisationsnummer. Används endats om Kontotyp är Företag.</span></span>
                                            <div class="input-field">
                                                <input class="form-control form-control-dashboard" type="text"
                                                       placeholder="OrgNr sätts till Invativas"
                                                       v-model="testUser.orgNr">
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-sm-12 col-lg-6 form-group form-group-dash mt-2">
                                            <label class="control-label">Address</label>&nbsp;<span class="tooltip"><i
                                            class="icon-info"></i><span class="tooltip-text">Om fältet lämnas tomt sätts det till Invativas</span></span>
                                            <div class="input-field">
                                                <input class="form-control form-control-dashboard" type="text"
                                                       placeholder="Address sätts till Invativas"
                                                       v-model="testUser.adr">
                                            </div>
                                        </div>

                                        <div class="col-sm-12 col-lg-6 form-group form-group-dash mt-2">
                                            <label class="control-label">PostNr</label>&nbsp;<span class="tooltip"><i
                                            class="icon-info"></i><span class="tooltip-text">Om fältet lämnas tomt sätts det till Invativas</span></span>
                                            <div class="input-field">
                                                <input class="form-control form-control-dashboard" type="text"
                                                       placeholder="PostNr sätts till Invativas"
                                                       v-model="testUser.postNr">
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-sm-12 col-lg-6 form-group form-group-dash mt-2">
                                            <label class="control-label">Stad</label>&nbsp;<span class="tooltip"><i
                                            class="icon-info"></i><span class="tooltip-text">Om fältet lämnas tomt sätts det till Invativas</span></span>
                                            <div class="input-field">
                                                <input class="form-control form-control-dashboard" type="text"
                                                       placeholder="Stad sätts till Invativas" v-model="testUser.city">
                                            </div>
                                        </div>

                                        <div class="col-sm-12 col-lg-6 form-group form-group-dash mt-2">
                                            <label class="control-label">Telenr</label>&nbsp;<span class="tooltip"><i
                                            class="icon-info"></i><span class="tooltip-text">Om fältet lämnas tomt sätts det till Invativas</span></span>
                                            <div class="input-field">
                                                <input class="form-control form-control-dashboard" type="text"
                                                       placeholder="Telenr sätts till Invativas"
                                                       v-model="testUser.teleNr">
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-sm-12 col-lg-6 form-group form-group-dash mt-2">
                                            <label class="control-label">Lösenord</label>&nbsp;<span class="tooltip"><i
                                            class="icon-info"></i><span class="tooltip-text">Om fältet lämnas tomt sätts det till ratsitT3st</span></span>
                                            <div class="input-field">
                                                <input class="form-control form-control-dashboard" type="text"
                                                       placeholder="Lösenord sätts till ratsitT3st"
                                                       v-model="testUser.password">
                                            </div>
                                        </div>

                                        <div class="col-sm-12 col-lg-6 form-group form-group-dash mt-2">
                                            <label class="control-label">Ratsitkonto</label>&nbsp;<span class="tooltip"><i
                                            class="icon-info"></i><span class="tooltip-text">Om fältet lämnas tomt sätts det till 200. Endast om kontotyp Företag.</span></span>
                                            <div class="input-field">
                                                <input class="form-control form-control-dashboard" type="text"
                                                       placeholder="Poäng sätts till 200 om Företags-konto"
                                                       v-model="testUser.ratsitPoints">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </v-content-toggle>
        </div>

        <div class="block block--primary">
            <div class="block__header">
                <h2 class="mt-3 mt-lg-5">Grundinställningar</h2>
            </div>
            <div class="block__section mt-2">
                <div class="row">
                    <div class="col-md-12">
                        <div class="form-horizontal">
                            <div class="row">

                                <div class="col-sm-12 col-lg-6 form-group form-group-dash mt-1">
                                    <label class="control-label">E-post</label>
                                    <div class="input-field">
                                        <input class="form-control form-control-dashboard" type="text"
                                               placeholder="E-post för användaren, lämna tomt för Automatgenererad"
                                               v-model="testUser.email">
                                    </div>
                                </div>

                                <div class="col-sm-12 col-lg-6 form-group form-group-dash mt-1">
                                    <v-content-toggle v-slot:default="slotProps">
                                        <div class="read-more user-select-auto"
                                             v-on:click="slotProps.toggleShowHide($event)">
                                            <label class="control-label">Hur vill du få e-posten? </label>
                                            <span class="read-more__toggle ms-2"
                                                  v-bind:class="{ 'is-active' : slotProps.isVisible }"></span>
                                        </div>
                                        <div v-if="slotProps.isVisible">

                                            <div id="emailinfo-container" class="mt-1">
                                                <ul>
                                                    <li><strong>Lämna tomt:</strong> du får en slumpmässig e-post och en
                                                        länk till en extern brevlåda. <i class="fa fa-info-circle"
                                                                                         data-toggle="tooltip"
                                                                                         data-placement="top"
                                                                                         title="Standard. Tjänsten https://generator.email/ används för detta."></i>
                                                    </li>
                                                    <li><strong>GMail:</strong> skapa och använda en GMail-adr med
                                                        +(plus). Ex 'minepost+testare1@gmail.com'. <i
                                                            class="fa fa-info-circle" data-toggle="tooltip"
                                                            data-placement="top"
                                                            title="För mer kontroll och mindre strul."></i></li>
                                                    <li><a href="https://www.mailinator.com/v3/#/#inboxpane"
                                                           target="_blank">Mailinator</a>: skapa enkelt en egen e-post
                                                        via externa tjänsten. <i class="fa fa-info-circle"
                                                                                 data-toggle="tooltip"
                                                                                 data-placement="top"
                                                                                 title="En mellanvariant, enklare än GMail men kan strula då det är en extern tjänst."></i>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </v-content-toggle>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-sm-12 col-lg-6 form-group form-group-dash mt-2">
                                    <label class="control-label">Kontotyp</label>
                                    <div class="input-select">
                                        <select class="form-control form-control-dashboard"
                                                v-model="testUser.accountType">
                                            <option value="privat">Privat</option>
                                            <option value="ftg">Företag</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-sm-12 col-lg-6 form-group form-group-dash mt-2">
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-sm-12 col-lg-6 form-group form-group-dash mt-2">
                                    <label class="control-label">Aktiverad</label>
                                    <div class="input-select">
                                        <select class="form-control form-control-dashboard"
                                                v-model="testUser.activated">
                                            <option value="y">Ja</option>
                                            <option value="n">Nej</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-sm-12 col-lg-6 form-group form-group-dash mt-2">
                                    <label class="control-label">Abonnemang</label>
                                    <div class="input-select">
                                        <select class="form-control form-control-dashboard" v-model="testUser.plus">
                                            <option value="n">Nej</option>
                                            <option value="plus1month">Plus 1 mån</option>
                                            <option value="premium1month">Premium 1 mån</option>
                                            <option value="premium1year">Premium 1 år</option>
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-sm-12 col-lg-6 form-group form-group-dash mt-2">
                                    <label class="control-label">Validerad</label>
                                    <div class="input-select">
                                        <select class="form-control form-control-dashboard"
                                                v-model="testUser.validated">
                                            <option value="y">Ja</option>
                                            <option value="n">Nej</option>
                                        </select>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col-md-12 mb-3">
                        <button :disabled="disableCreateTestUser" class="btn btn-primary"
                                v-on:click="createTestUser">Skapa användaren
                        </button>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script lang="ts">
import axios from "axios";
import {defineComponent, ref} from "vue";

export type TestUser =
    {
        email: string
        accountType: string
        activated: string
        plus: string
        validated: string
        gender: string
        pNr: string
        fName: string
        lName: string
        password: string
        orgNr: string
        orgName: string
        adr: string
        postNr: string
        city: string
        teleNr: string
        ratsitPoints: string
    }

export type TestUserCreateResponse =
    {
        error: boolean
        message: string
        userinfo: TestUser | undefined
    }

export default defineComponent({
    setup() {
        const testUser = ref<TestUser>({
            email: '',
            accountType: 'privat',
            activated: 'y',
            plus: 'n',
            validated: 'y',
            gender: 'slump',
            pNr: '',
            fName: '',
            lName: '',
            password: '',
            orgNr: '',
            orgName: '',
            adr: '',
            postNr: '',
            city: '',
            teleNr: '',
            ratsitPoints: '',
        })

        const disableCreateTestUser = ref<boolean>(false)
        const showCreatedUser = ref<boolean>(false)
        const createdUser = ref<TestUser>()
        const isGeneratorEmail = ref<boolean>(false)
        const generatorEmailLink = ref<string>('')
        const error = ref<boolean>(false)
        const message = ref<string>('')

        const createTestUser = async () => {
            disableCreateTestUser.value = true

            const response = await axios.post<TestUserCreateResponse>(`/playgrounduser/createtestuser`, testUser.value)

            error.value = response.data.error
            message.value = response.data.message
            createdUser.value = response.data.userinfo
            if (createdUser.value?.email.endsWith('mail-temp.com')) {
                isGeneratorEmail.value = true
                generatorEmailLink.value = `https://generator.email/{createdUser.value?.email}`
            } else {
                isGeneratorEmail.value = false
                generatorEmailLink.value = ''
            }

            testUser.value = {
                email: '',
                accountType: 'privat',
                activated: 'y',
                plus: 'n',
                validated: 'y',
                gender: 'slump',
                pNr: '',
                fName: '',
                lName: '',
                password: '',
                orgNr: '',
                orgName: '',
                adr: '',
                postNr: '',
                city: '',
                teleNr: '',
                ratsitPoints: '',
            }

            showCreatedUser.value = !response.data.error
            disableCreateTestUser.value = false

            const scrollToId = response.data.error ? 'error' : 'created-user'
            const e = document.getElementById(scrollToId)
            if (e) {
                setTimeout(() => {
                    e.scrollIntoView(true)
                }, 200)
            }
        }

        const signInTestUser = async () => {
            await axios.post(`/playgrounduser/loginuser`, {
                email: createdUser.value?.email,
                password: createdUser.value?.password
            }).then(() => window.location.assign("/"))
        }
        const copyToClipboard = (value: string) => {
            navigator.clipboard.writeText(value)
        }

        return {
            createTestUser,
            signInTestUser,
            testUser,
            disableCreateTestUser,
            isGeneratorEmail,
            createdUser,
            error,
            message,
            showCreatedUser,
            generatorEmailLink,
            copyToClipboard
        }
    }
})
</script>