import {defineStore} from 'pinia';
import axios from "axios"
import {
    CompanyInfoViewModel,
    SignInWizardModel,
} from "@/Purchase/purchase-models"
import {useSessionStorageRef} from './sessionStorage'
import {ref} from "vue";

type BankIdMode = 'init' | 'device' | 'another-device';
export const useSharedStore = defineStore('shared', () => {
    // State
    const errorMessage = useSessionStorageRef({key: 'shared.errorMessage', defaultValue: ''})
    const isUserAuthenticated = useSessionStorageRef({key: 'shared.isUserAuthenticated', defaultValue: false})
    const userHasMobilPhonenumber = useSessionStorageRef({key: 'shared.userHasMobilPhonenumber', defaultValue: false})
    const userMobilPhonenumber = useSessionStorageRef({key: 'shared.userMobilPhonenumber', defaultValue: ''})
    const userIsValidated = useSessionStorageRef({key: 'shared.userIsValidated', defaultValue: false})
    const turnstileKey = useSessionStorageRef({key: 'shared.turnstileKey', defaultValue: ''})
    const cancelRedirect = useSessionStorageRef({key: 'shared.cancelRedirect', defaultValue: ''})
    const confirmRedirect = useSessionStorageRef({key: 'shared.confirmRedirect', defaultValue: ''})
    const purpose = useSessionStorageRef({key: 'shared.purpose', defaultValue: ''})
    const mode = useSessionStorageRef<BankIdMode>({key: 'validation.mode', defaultValue: 'init'})
    const editPersonReportToken = useSessionStorageRef({key: 'shared.editPersonReportToken', defaultValue: ''})

    // Actions
    const setEditPersonReportToken = (value: string) =>{
        editPersonReportToken.value = value;
    }
    const clearEditPersonReportToken = () =>{
        editPersonReportToken.value = ''
    }

    const setMode = (value: BankIdMode) =>
    {
        mode.value = value
    }

    const clearMode = () =>
    {
        mode.value = 'init'
    }

    const setPurpose = (value: string) => {
        purpose.value = value
    }

    const setCancelRedirect = (path: string) => {
        cancelRedirect.value = path
    }

    const setConfirmRedirect = (path: string) => {
        confirmRedirect.value = path
    }


    const initSignInWizard = async (turnstileSiteKey: string | null = null): Promise<SignInWizardModel> => {
        if (turnstileSiteKey) {
            turnstileKey.value = turnstileSiteKey
        }
        const res =
            await axios.get<SignInWizardModel>(`/purchase/kreditupplysningwizard`)
        return res.data
    }

    const bankIdValidationComplete = async (): Promise<void> => {
        await axios.get(`/purchase/bankIdValidationComplete`)
    }

    const sendVerificationCode = async (): Promise<boolean> => {
        const res = await axios.get<boolean>(`/purchase/skicka/kod`)
        return res.data
    }

    const checkVerificationCode = async (code: string): Promise<boolean> => {
        const res = await axios.get<boolean>(`/purchase/kontrollera/kod/${code}`)
        return res.data
    }

    const getCompanyInfo = async (orgNumber: string): Promise<CompanyInfoViewModel | undefined> => {
        const companyInfo = await axios.get<CompanyInfoViewModel>(`/purchase/info/company/${orgNumber}`)

        return {
            companyName: companyInfo.data.companyName,
            companyAddress: companyInfo.data.companyAddress,
            companyZipCode: companyInfo.data.companyZipCode,
            companyCity: companyInfo.data.companyCity
        }
    }




    return {
        errorMessage,
        isUserAuthenticated,
        userIsValidated,
        checkVerificationCode,
        sendVerificationCode,
        userHasMobilPhonenumber,
        userMobilPhonenumber,
        initSignInWizard,
        bankIdValidationComplete,
        turnstileKey,
        confirmRedirect,
        cancelRedirect,
        setCancelRedirect,
        setConfirmRedirect,
        purpose,
        setPurpose,
        mode,
        setMode,
        clearMode,
        getCompanyInfo,
        editPersonReportToken,
        setEditPersonReportToken,
        clearEditPersonReportToken
    }
})
