<template>
    <div class="w-10-0 d-flex justify-content-center d-md-inline-block">
        <div v-html="content" class="content"></div>
    </div>
</template>

<script>
import {onMounted, ref} from "vue";
import QRCode from 'qrcode'

export default {
    name: "v-qr-code",
    props: {
        text: {
            type: String,
            required: true
        }
    },
    setup(props) {
        let content = ref('');

        onMounted(() => {
            let options = {
                type: 'svg',
                version: 7
            }

            QRCode.toString(props.text, options, function(err, string) {
                if (err)
                    throw err;

                content.value = string;
            });
        })

        return {
            content
        }
    }
}
</script>

<style scoped>
    .content {
        width: 200px;
        height: 200px;
    }
</style>