<template>
    <div ref="checkboxContainer">
        <slot />
    </div>
</template>
<script>
import {onMounted, ref} from "vue";

export default {
    props: {
        checked: {
            type: Boolean,
            default: null
        }
    },
    emits: ['change'],
    setup(props, ctx) {
        const checkboxContainer = ref(null);

        onMounted(() => {
            const container = checkboxContainer.value;
            const label = container.querySelector('label');

            if (!label) {
                console.error('Label not found in slot content');
                return;
            }

            label.classList.add('ratsit-checkbox');

            const checkbox = label.firstElementChild;
            toggleCheckbox(checkbox);

            checkbox.addEventListener('change', () => {
                ctx.emit('change', checkbox.checked);
                toggleCheckbox(checkbox);
            });

            checkbox.addEventListener('focus', () => {
                setFocusOnText(checkbox);
            });

            checkbox.addEventListener('blur', () => {
                looseFocusOnText(checkbox);
            });

            setCheckboxStatus(label);
        });

        return {
            checkboxContainer,
        }
    },
};

function toggleCheckbox(checkbox) {
    const label = checkbox.parentElement;

    if (!checkbox) return;

    if (checkbox.checked) {
        label.classList.add('is-checked');
    }
    else {
        label.classList.remove('is-checked');
    }
}

function setCheckboxStatus(label) {
    const checkbox = label.firstChild;

    if (checkbox.checked) {
        label.classList.add('is-checked');
    }
}

function setFocusOnText(checkbox) {
    checkbox.nextElementSibling.style.border = '1px dotted #999';
}

function looseFocusOnText(checkbox) {
    checkbox.nextElementSibling.style.border = '1px solid transparent';
}
</script>