export default {
    mounted(el) {
        el.addEventListener('click', scrollOnClick);
    }
}

function scrollOnClick(e) {
    var targetHash;

    if (e.currentTarget.hash) {
        targetHash = e.currentTarget.hash;
    }
    else if (e.currentTarget.parentElement.hash) {
        targetHash = e.currentTarget.parentElement.hash;
    }
    else {
        targetHash = e.target.hash;
    }


    e.preventDefault();
    window.location.hash = targetHash;

    const element = document.querySelector(targetHash);
    scrollToSection(element);
}

function scrollToSection(section) {
    if (section) {
        window.scrollTo({
            behavior: 'smooth',
            left: 0,
            top: section.offsetTop - 0
        });
    }
}
