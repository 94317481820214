<template>
    <div>
        <slot :is-visible="isVisible"
              :tab1="tab1"
              :tab2="tab2"
              :map1="map1"
              :map2="map2"
              :toggle-active="toggleActive"
              :toggle-active-map="toggleActiveMap"
              :toggle-ABC="toggleABC"
              :toggle-show-hide="toggleShowHide"
              :toggle-info="toggleInfo"></slot>
    </div>
</template>
<script>

    export default {
        props: {
          isVisibleInit: {
              type: Boolean,
              required: false,
              default: false
          }
        },
        data() {
            return {
                isVisible: false,
                tab1: true,
                tab2: false,
                map1: true,
                map2: false
            }
        },
        created() {
          this.isVisible = this.isVisibleInit;
        },
        methods: {

            toggleActive(tab) {

                if (tab === 'tab1') {
                    this.tab1 = true;
                    this.tab2 = false;
                }
                else if (tab === 'tab2') {
                    this.tab1 = false;
                    this.tab2 = true;
                }

            },

            toggleActiveMap(map) {

                if (map === 'map1') {
                    this.map1 = true;
                    this.map2 = false;
                }
                else if (map === 'map2') {
                    this.map1 = false;
                    this.map2 = true;
                }

            },

            toggleABC(e) {
                var el = e.currentTarget;
                var li = el.parentElement;

                let divEl = el.parentElement.parentElement.parentElement.querySelectorAll('ul');

                if (divEl) {
                    divEl.forEach(v => {
                        if (v && v.children) {
                            for (var i = 0, l = v.children.length; i < l; i++) {
                                v.children[i].classList.remove('is-active');
                            }
                        }
                    });
                }

                li.classList.add('is-active');
            },

            toggleShowHide(e) {

                this.isVisible ? this.isVisible = false : this.isVisible = true;
            },

            toggleInfo(id, e) {
                var el = document.getElementById(id);
                var currentEl = e.currentTarget;

                if (el) {

                    if (el.classList.contains('d-none')) {
                        el.classList.remove('d-none');
                        currentEl.classList.add('open');
                    }
                    else {
                        el.classList.add('d-none');
                        currentEl.classList.remove('open');
                    }

                }
            }
        }
    }
</script>